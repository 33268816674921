<template>
  <div>
    <HomeContent></HomeContent>
  </div>
</template>

<script>
import HomeContent from "./home-content.vue";

import { VUE_APP_ID, VUE_UNION_STATION } from "@/assets/js/stationSetting";
export default {
  components: {
    HomeContent,
  },

  data() {
    return {
      VUE_APP_ID,
    };
  },
  computed: {},
  created() {},
};
</script>

<style></style>
