<template>
  <div class="api-key-setting">
    <div class="trade-container">
      <div class="header">
        <div class="content-title">
          <h2>{{ translateTitle("API设置") }}</h2>
          <slot name="view"></slot>
        </div>
        <div class="title-detail">
          <div class="detail">
            <h2>{{ translateTitle("MaskEX为客户提供了强大API的功能") }}</h2>
            <p>
              {{
                translateTitle(
                  "您可以使用API密钥来获取市场数据，处理自动交易订单等操作。"
                )
              }}
            </p>
            <p>{{ translateTitle("每个账户最多可以有20个API密钥。") }}</p>
            <p>
              {{
                translateTitle(
                  "为了安全起见，请不要向不信任的第三方透露您的API密钥。"
                )
              }}
            </p>
            <p>
              {{
                translateTitle(
                  "建议IP地址添加到每个API密钥中，每个API密钥最多可以添加30个IP地址。"
                )
              }}
            </p>
            <p>
              {{
                translateTitle(
                  "如果API密钥没有绑定IP地址，则有效期为3个月，如果绑定了IP地址，则永久有效。"
                )
              }}
            </p>
            <p>{{ translateTitle("请妥善保管您的API密钥。") }}</p>
            <span class="btn-add" @click="addClick">
              {{ translateTitle("创建新密钥") }}
            </span>
          </div>
        </div>
        <div class="bill-center-content">
          <!-- 列表 -->
          <!-- <div class="filter-box">
            <el-input
              v-model="app_id"
              :placeholder="translateTitle('API KEY')"
              :clearable="true"
              class="selectStyle"
            >
            </el-input>
            <div class="twentyWid"></div>
            <span class="btn-search" @click="initData">{{
              translateTitle("查询")
            }}</span>
            <div class="tenWid"></div>
          </div> -->
          <!-- 持仓表格开始 -->
          <el-table :data="dataList" v-loading="tableLoading">
            <el-table-column
              class="coin-cell"
              width="85px"
              prop="id"
              align="content"
              :show-overflow-tooltip="true"
              :label="translateTitle('ID')"
            >
              <template slot-scope="{ row }">
                <span>{{ row.id }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class="coin-cell"
              width="140px"
              align="left"
              :show-overflow-tooltip="true"
              :label="translateTitle('名称')"
            >
              <template slot-scope="{ row }">
                <span>{{ row.name }}</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="show_name"
              min-width="180px"
              align="center"
              :label="translateTitle('API KEY')"
            >
              <template slot-scope="{ row }">
                <div>{{ row.api_key }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="show_name"
              min-width="80px"
              align="center"
              :label="translateTitle('API Secret')"
            >
              <template slot-scope="{ row }">
                <span class="lookkey" @click="lookmore(row)">
                  {{ translateTitle("查看") }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="show_name"
              min-width="80px"
              align="center"
              :label="translateTitle('状态')"
            >
              <template slot-scope="{ row }">
                <div
                  :class="
                    row.status == 2 ? 'income-color-red' : 'income-color-green'
                  "
                >
                  {{ formatStatus(row.status) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="show_name"
              min-width="140px"
              align="center"
              :label="translateTitle('有效期')"
            >
              <template slot-scope="{ row }">
                <div>{{ formatDate(row.expire_time) }}</div>
              </template>
            </el-table-column>

            <el-table-column
              min-width="140px"
              :label="translateTitle('创建时间')"
            >
              <template slot-scope="{ row }">
                <span>{{
                  moment(row.create_time * 1000).format("YYYY/MM/DD HH:mm:ss")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              width="160"
              :label="translateTitle('操作')"
              fixed="right"
              align="center"
            >
              <template slot-scope="{ row }" style="text-align: center">
                <span class="operation mr20" @click="editData(row)">{{
                  translateTitle("编辑")
                }}</span>

                <span
                  class="operation"
                  @click="deleteRow(row)"
                  slot="reference"
                  >{{ translateTitle("删除") }}</span
                >
              </template>
            </el-table-column>

            <!-- 表格结束 -->
            <no-data
              slot="empty"
              v-show="!tableLoading && dataList.length == 0"
            />
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog
      width="600px"
      :modal="true"
      :title="
        currType == 1 ? translateTitle('创建新密钥') : translateTitle('编辑')
      "
      :destroy-on-close="true"
      :visible.sync="addEditVisible"
      :show-close="true"
    >
      <addEditApiKey
        v-if="addEditVisible"
        :curr-row="currRow"
        :currType="currType"
        @update-success="onUpdateSuccess"
        @update-cancel="onUpdateCancel"
      ></addEditApiKey>
    </el-dialog>
    <el-dialog
      width="300px"
      :modal="true"
      :title="translateTitle('查看密钥')"
      :destroy-on-close="true"
      :visible.sync="lookmoreVisible"
      :show-close="true"
      @open="oncloseGoogle"
    >
      <div v-if="api_secret">
        <h3>{{ translateTitle("请妥善保管您的API密钥") }}</h3>
        <p>
          {{ api_secret + " " }}
          <i
            class="iconfont iconicon-copy"
            v-clipboard:copy="api_secret"
            v-clipboard:success="copySuccess"
            v-clipboard:error="copyError"
          ></i>
        </p>
      </div>
      <div v-if="!api_secret">
        <span class="txt-title">{{
          translateTitle("查看密钥需要输入谷歌码")
        }}</span>
        <el-input
          v-model="googleCode"
          :maxlength="6"
          :placeholder="translateTitle('请输入谷歌码')"
        >
        </el-input>
      </div>
      <div class="btn-list">
        <span class="btn-reset ml20" @click="lookmoreVisible = false">{{
          translateTitle("取消")
        }}</span>
        <span
          class="btn-search"
          v-if="!api_secret"
          :loading="btnLoading"
          @click="onlook"
          >{{ translateTitle("查看") }}</span
        >
      </div>
    </el-dialog>
    <el-dialog
      class="c-dialog"
      width="440px"
      :title="translateTitle('确认删除')"
      :visible.sync="delVisible"
      :close-on-click-modal="false"
      @open="oncloseGoogle"
    >
      <div class="content">
        <div>{{ translateTitle("您确定要进行本次操作吗？") }}</div>

        <el-input
          v-model="googleCode"
          :maxlength="6"
          :placeholder="translateTitle('请输入谷歌码')"
        >
        </el-input>

        <div class="btn-list">
          <span class="btn-reset ml20" @click="delVisible = false">{{
            translateTitle("取消")
          }}</span>
          <span
            class="btn-search"
            @click="onDeleteConfirm"
            :loading="delLoading"
            >{{ translateTitle("确认") }}</span
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import NoData from "@/components/common/NoData";
import commonMixin from "@/components/common/commonMixin.vue";
import menuControlMixin from "@/components/common/menuControlMixin.vue";
import { showMessage } from "@/components/dialog/message";
import { mapGetters } from "vuex";
import { translateTitle } from "@/assets/js/i18n";
import { VUE_APP_ID, VUE_UNION_STATION } from "@/assets/js/stationSetting";
import {
  apiApiSecretGetList,
  apiApiSecretSecretInfo,
  apiApiSecretDelete,
} from "@/model/ucenter/billCenter";
import addEditApiKey from "./components/add-edit-api-setting.vue";
import b9Logo from "@/assets/img/siteLogo/b9.png";
import maskLogo from "@/assets/img/siteLogo/mask_new.png";
import bxcLogo from "@/assets/img/siteLogo/bxc.png";
import hxLogo from "@/assets/img/siteLogo/hx.png";
import logo100 from "@/assets/img/siteLogo/logo100.png";
import hxlogo_text from "@/assets/img/siteLogo/hxlogo_text.png";
import logo100_text from "@/assets/img/siteLogo/logo100_text.png";
import mask_text from "@/assets/img/siteLogo/mask_text.png";
import b9_text from "@/assets/img/siteLogo/b9_text.png";
import qrCode from "@/assets/img/qr_code.png";
import profitLossShareBg from "@/assets/img/profit_loss_share_bg.png";
import placeholder from "@/assets/img/placeholder.png";
import expressionBad from "@/assets/img/expression/expression-bad.png";
import expressionGreat from "@/assets/img/expression/expression-great.png";
import expressionCool from "@/assets/img/expression/expression-cool.png";
import routeSplicing from "@/assets/js/routeSplicing";
import moment from "moment";
export default {
  mixins: [commonMixin, menuControlMixin],
  components: {
    NoData,
    addEditApiKey,
  },
  data() {
    return {
      currType: "",
      currRow: {},
      delVisible: false,
      addEditVisible: false,
      placeholder,
      delLoading: false,
      isDestoryWS: false,
      wsContentTime: 0,
      assetsInfo: {
        remain: {
          all_u_valuation: {
            usdt: "--",
          },
          list: [],
        },
      },
      marketList: [],
      appendMoneydialog: false,
      staticChainMap: {}, //静态表1
      staticCurrencyMap: {},
      sotcStaticPaymentMap: {},
      sotcStaticProtectMap: {},
      sotcStaticGuaranteeMap: {},
      copyAccountMsg: "",
      googleCode: "",
      apiAssetsInfo: {},
      from_item_id: "",
      to_item_id: "",
      from_symbol: "",
      to_symbol: "",
      swap_symbol_id: "",
      beforeBailNumber: "",
      afterBailNumber: "",
      beforeLiquidationPrice: "",
      afterLiquidationPrice: "",
      symbolList: {},
      price_precision: "",
      settlement_precision: "",
      contract_value: "",
      fee_rate: "",
      liquidation_risk_rate: "",
      bail_number: "",
      rowData: {},
      row_swap_symbol_id: "",
      inputProfitPrice: "",
      inputLossPrice: "",
      setProfitPricedialog: false,
      dialogType: "",
      confirmMessage: "",
      plan_order_id: "",
      dialogVisible: false,
      profitLossShareBg,
      expressionBad,
      expressionCool,
      expressionGreat,

      notifyImg: "",
      notifyVisible: false,
      painting: {},
      b9Logo,
      bxcLogo,
      hxLogo,
      logo100,
      maskLogo,
      hxlogo_text,
      logo100_text,
      app_id: "",
      mask_text,
      b9_text,
      qrCode,
      projectEnglishName: "",
      projectFullName: "",
      slogan: "",
      projectTitle: "",
      projectLogo: "",
      default_invite_code: {},
      centerHost: "",
      operateTypeMap: {
        1: this.translateTitle("开仓"),
        2: this.translateTitle("平仓"),
        3: this.translateTitle("设置止损价"),
        4: this.translateTitle("设置止盈价"),
        5: this.translateTitle("追加本金"),
        6: this.translateTitle("已强平"),
        7: this.translateTitle("同时设置止盈止损"),
        8: this.translateTitle("加仓"),
        9: this.translateTitle("减仓"),
        10: this.translateTitle("资金费用"),
      },
      position_order_id: "",
      position_order_obj: { position_info: {}, operate_list: [] },
      user_order_obj: {},
      closePositionTypeMap: {
        1: this.translateTitle("未平仓"),
        2: this.translateTitle("用户平仓"),
        3: this.translateTitle("强制平仓"),
        4: this.translateTitle("止盈平仓"),
        5: this.translateTitle("止损平仓"),
        6: this.translateTitle("自动减仓"),
      }, //平仓类型
      openPositionTypeMap: {
        1: this.translateTitle("手动开仓"),
        2: this.translateTitle("触发价开仓"),
      }, //开仓类型
      liquidation_symbol_name: "",
      coinData: {},
      staticSymbolData: {},
      staticFuturesSymbolData: {},
      searchCoin: [],
      staticAssetsTypeData: [],
      staticStockData: {},
      searchStaticAssetsType: [],
      billStatusMap: [
        {
          value: 1,
          label: this.translateTitle("进行中"),
        },
        {
          value: 2,
          label: this.translateTitle("已完成"),
        },
        {
          value: 3,
          label: this.translateTitle("失败"),
        },
      ],
      searchBillStatus: [],
      ctimeAndEtime: [],
      dataList: [],

      opDirectionMap: {
        1: this.translateTitle("收入"),
        2: this.translateTitle("支出"),
      },
      btnLoading: false,
      tableLoading: true,
      lookmoreVisible: false,
      billDialogTitle: "",
      billDialogVisible: false,
      reChargeDialogVisible: false,
      otcDialogVisible: false,

      tabList: [
        { label: this.translateTitle("收款账号"), value: 1 },
        { label: this.translateTitle("付款截图"), value: 2 },
        { label: this.translateTitle("订单历史"), value: 3 },
      ],

      currIndex: 1,
      googleStatus: 1, // 谷歌验证状态
      currIndexPosition: 1,
      api_secret: "",
      coinInfoMap: {},
      pageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1,
      },
      isDoBS: false,
      isBuy: true,
      //   currRow: {},
    };
  },

  async created() {
    let { authInfo } = sessionStorage.userInfo
      ? JSON.parse(sessionStorage.userInfo)
      : {};
    if (authInfo) {
      this.googleStatus = authInfo.open_google;
    }
    this.initData();

    if (
      (this.language && VUE_APP_ID == "UhkNBRbpeSGTuaf0yuiG1Yin") ||
      VUE_APP_ID == "OCzy5RbpeSGTuaf0yuiG1Yin"
    ) {
      this.projectFullName = "Bullxcow";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("Bullxcow App下载");
      this.projectLogo = this.bxcLogo;
    } else if (this.language && VUE_APP_ID == "9wZvazddcsWrnWtnB7Zhy399") {
      this.projectFullName = "B9";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("B9 App下载");
      this.projectLogo = this.b9Logo;
    } else if (this.language && VUE_UNION_STATION == "MaskEx") {
      this.projectFullName = "MaskEX";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("MaskEX App下载");
      this.projectLogo = this.maskLogo;
    } else if (this.language && VUE_UNION_STATION == "100Ex") {
      this.projectFullName = "100Ex";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("100Ex App下载");
      this.projectLogo = this.logo100;
    } else if (this.language && VUE_UNION_STATION == "EverTrust") {
      this.projectFullName = "EverTrust";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("EverTrust App下载");
    } else if (this.language && VUE_UNION_STATION == "8V") {
      this.projectFullName = "8V";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("8V App下载");
    } else if (this.language && VUE_UNION_STATION == "UEEx") {
      this.projectFullName = "UEEx";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("UEEx App");
    } else if (this.language && VUE_UNION_STATION == "Ahex") {
      this.projectFullName = "AHEX";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = "AHEX" + this.translateTitle(" App下载");
    } else if (this.language && VUE_UNION_STATION == "5A") {
      this.projectFullName = "5A";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = "5A" + this.translateTitle("App下载");
    } else if (VUE_UNION_STATION == "bitda") {
      this.projectFullName = "bitda";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = "bitda" + this.translateTitle("App下载");
    } else if (VUE_UNION_STATION == "S6") {
      this.projectFullName = "S6";
      this.slogan = this.translateTitle(`数字理财 赢得未来`);
      this.projectTitle = "S6" + this.translateTitle("App下载");
    } else if (VUE_UNION_STATION == "easy") {
      this.projectFullName = "ezex";
      this.slogan = this.translateTitle(`数字理财 赢得未来`);
      this.projectTitle = "ezex" + this.translateTitle("App下载");
    } else if (VUE_UNION_STATION == "spex") {
      this.projectFullName = "SPEX";
      this.slogan = this.translateTitle(`数字理财 赢得未来`);
      this.projectTitle = "SPEX" + this.translateTitle("App下载");
    } else {
      this.projectFullName = "汇信国际";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("汇信App下载");
      this.projectLogo = this.hxLogo;
    }
  },
  destroyed() {},
  beforeDestroy() {},
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),
  },
  methods: {
    routeSplicing,
    translateTitle,
    moment,
    // async getGooglStatus() {
    //   let { status, data, msg } = await bingGoogleVerifyStatus();
    //   if (status !== 200) {
    //     return;
    //   }
    //   this.googleStatus = data.bing_google_status;
    // },
    oncloseGoogle() {
      this.googleCode = "";
      this.api_secret = "";
      //   this.lookmoreVisible = false;
    },
    onlook() {
      if (!this.googleCode) {
        showMessage({ message: this.translateTitle(`谷歌码不能为空`) });
        return;
      }
      this.btnLoading = true;
      apiApiSecretSecretInfo({
        google_code: this.googleCode,
        api_key: this.currRow.api_key,
      }).then(rs => {
        this.btnLoading = false;
        if (rs.status == 200) {
          console.log("rs", rs);
          if (rs?.data?.api_secret) {
            this.api_secret = rs.data.api_secret;
          }
        }
      });
    },
    onDeleteConfirm() {
      if (!this.googleCode) {
        showMessage({ message: this.translateTitle(`谷歌码不能为空`) });
        return;
      }
      this.delLoading = true;
      apiApiSecretDelete({
        id: this.currRow.id,
        google_code: this.googleCode,
      }).then(rs => {
        this.delLoading = false;
        if (rs.status == 200) {
          this.delVisible = false;
          showMessage({
            message: this.translateTitle(`删除成功`),
            type: "success",
          });
          this.initData();
        }
      });
    },
    async deleteRow(row) {
      if (!this.googleStatus) {
        showMessage({
          message: this.translateTitle(`请在安全设置绑定谷歌码`),
        });
        return;
      }
      this.currRow = row;
      this.delVisible = true;
    },
    formatStatus(row) {
      return row == 1
        ? this.translateTitle(`开启`)
        : this.translateTitle(`禁用`);
    },
    formatDate(row) {
      if (row == 0) {
        return this.translateTitle(`永久`);
      }
      return this.moment(row * 1000).format("YYYY/MM/DD HH:mm:ss");
    },
    lookmore(row) {
      if (!this.googleStatus) {
        showMessage({
          message: this.translateTitle(`请在安全设置绑定谷歌码`),
        });
        return;
      }
      this.currRow = row;
      this.lookmoreVisible = true;
    },
    addClick() {
      //   if (!this.googleStatus) {
      //     showMessage({
      //       message: this.translateTitle(`请在安全设置绑定谷歌码`),
      //     });
      //     return;
      //   }

      this.currType = 1;
      this.addEditVisible = true;
    },
    editData(row) {
      if (!this.googleStatus) {
        showMessage({
          message: this.translateTitle(`请在安全设置绑定谷歌码`),
        });
        return;
      }
      this.currType = 2;
      this.currRow = row;
      this.addEditVisible = true;
    },
    onUpdateSuccess() {
      this.addEditVisible = false;
      this.initData();
    },
    onUpdateCancel() {
      this.addEditVisible = false;
    },
    // 复制成功
    copySuccess() {
      this.$message({
        message: this.translateTitle(`复制成功`),
        type: "success",
        customClass: "zZindex",
      });
    },

    copyError() {
      showMessage({ message: this.translateTitle(`复制失败`) });
    },
    async resetData(pageNum = 1) {
      this.tableLoading = true;
      this.app_id = "";
      const { status, data } = await apiApiSecretGetList({});
      if (status && status == 200) {
        let tempData = data;
        this.dataList = tempData;
      }
      this.tableLoading = false;
    },
    async initData(pageNum = 1) {
      const req = {
        app_id: this.app_id,
      };
      this.tableLoading = true;
      const { status, data } = await apiApiSecretGetList(req);
      if (status && status == 200) {
        data?.forEach(item => {
          item.visible = false;
        });
        this.dataList = data;
      }
      this.tableLoading = false;
    },
  },
};
</script>
<style lang="scss">
.zZindex {
  z-index: 3000 !important;
}
.api-key-setting {
  .mr20 {
    margin-right: 20px;
  }
  .income-color-red {
    color: rgb(240 74 90) !important;
  }
  .income-color-green {
    color: rgb(0 188 160) !important;
  }
  .btn-list {
    text-align: center;
    margin-top: 40px;
    span {
      margin-right: 20px;
    }
  }
  .txt-title {
    margin-bottom: 20px;
    display: inline-block;
  }
  .btn-search {
    user-select: none;
    cursor: pointer;
    width: 120px;
    height: 16px;
    padding: 10px 21px;
    border-radius: 3px;
    background: linear-gradient(68deg, #00cf88 21%, #0084d6 82%) !important;
    color: #fff;
    font-size: 14px;
  }
  .btn-reset {
    user-select: none;
    cursor: pointer;
    width: 120px;
    height: 16px;
    padding: 9px 20px;
    border-radius: 3px;
    color: #354052;
    font-size: 14px;
    border: solid 1px #c2c7d0;
    box-sizing: border-box;
  }
  .title-detail {
    padding: 0px 20px;
    background: #ffffff;
    overflow: hidden;
    margin: 20px;
    min-height: calc(100vh - 1000px);
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
    .detail {
      h2 {
        margin-bottom: 20px;
      }
      margin: 20px 0;
      p {
        font-size: 10px;
        font-weight: 200;
        color: #354052;
        line-height: 2;
      }
    }
    .btn-add {
      margin-top: 20px;
      background: linear-gradient(68deg, #00cf88 21%, #0084d6 82%) !important;
      display: inline-block;
      border-radius: 2px;
      cursor: pointer;
      color: #fff;
      padding: 5px 20px;
      font-size: 14px;
    }
  }
  .lookkey {
    border-radius: 2px;
    cursor: pointer;
    color: #fff;
    font-size: 12px;
    padding: 3px 7px;
    background: linear-gradient(68deg, #00cf88 21%, #0084d6 82%) !important;
  }
  .us-stock-icon {
    border-radius: 2px;
    background-color: rgba(240, 74, 90, 0.6);
    color: #fff;
    font-size: 11px;
    padding: 0 2px;
    margin-right: 4px;
  }
  .hk-stock-icon {
    border-radius: 2px;
    background-color: rgba(58, 125, 255, 0.6);
    color: #fff;
    font-size: 11px;
    padding: 0 2px;
    margin-right: 4px;
  }
  .order-status-yellow {
    color: rgb(247 135 0) !important;
  }
  .order-status-red {
    color: rgb(240 74 90) !important;
  }
  .income-color-red {
    color: rgb(240 74 90) !important;
  }
  .income-color-green {
    color: rgb(0 188 160) !important;
  }
  .income-color-grey {
    color: #c2c7d0 !important;
  }
  .income-color-black {
    color: #354052 !important;
  }

  .append-money-dialog {
    .el-dialog__header {
      padding: 20px 30px 20px;
    }
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .el-dialog__body {
      padding: 0px 30px 5px;
    }
    .el-dialog__footer {
      padding: 10px 20px 30px;
    }
    .first-content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .available-assets {
        color: #bec1c8;
      }
    }
    .second-content {
      position: relative;
      .el-input__suffix {
        line-height: 40px;
        margin-right: 40px;
        color: #354052;
        .el-input__suffix-inner {
          font-size: 12px;
        }
      }
      .all-btn {
        position: absolute;
        right: 15px;
        top: 12px;
        font-size: 12px;
      }
    }
    .three-content {
      width: 440px;
      height: 120px;
      margin: 20px 30px 20px 0px;
      padding: 14px 15px;
      border-radius: 4px;
      background-color: #f8f8f8;
      .item-one,
      .item-two {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        span {
          display: block;
          margin-bottom: 2px;
          color: #7f8490;
          font-size: 12px;
          &.black {
            font-size: 14px;
            font-weight: 500;
            color: #354052;
          }
          &.red {
            font-weight: 500;
            font-size: 14px;
            color: #f04a5a;
          }
        }
        .left-item {
          width: 150px;
        }
        .right-item {
          width: 150px;
          text-align: right;
        }
      }
      img {
        width: 22px;
        height: 22px;
      }
    }
  }
  /* dialog居中开始 */
  .order-dialog .el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 60px);
    max-width: calc(100% - 30px);
  }
  .order-dialog .el-dialog .el-dialog__body {
    flex: 1;
    overflow: auto;
    &::-webkit-scrollbar-track-piece {
      background-color: #fff;
      border-radius: 4px;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #dddddd;
      border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #bbb;
    }
  }
  /* dialog居中结束 */
  .liquidation-dialog {
    .el-dialog__header {
      font-size: 20px;
      font-weight: 500;
      padding: 20px 30px 10px;
    }
    .el-dialog__body {
      padding: 10px 30px 30px;
    }
    .time-block {
      width: 100%;
      padding: 20px 15px 0 4px;
      .cardStep {
        display: flex;
        flex-direction: column;
        .time {
          color: rgb(154 161 177);
          font-size: 13px;
        }
        .contentStep {
          margin-top: 5px;
          display: flex;
          justify-content: space-between;
          .sub-title {
            color: rgb(53 64 82);
            font-size: 14px;
          }
          .sub-content {
            color: rgb(53 64 82);
            font-size: 14px;
          }
        }
      }
      .cardStartAndEnd {
        display: flex;
        flex-direction: column;
        .time-copyinfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .time {
            color: rgb(154 161 177);
            font-size: 13px;
            display: flex;
          }
          .copyinfo {
            .title {
              color: rgb(154 161 177);
              font-size: 14px;
            }
            .info {
              color: #354052;
              font-size: 14px;
            }
          }
        }

        .titleStartAndEnd {
          margin: 5px 0;
          color: rgb(53 64 82);
          font-size: 15px;
        }
        .contentStartAndEnd {
          margin-bottom: 3px;
          display: flex;
          justify-content: space-between;
          .sub-title {
            color: rgb(154 161 177);
            font-size: 13px;
          }
          .sub-content {
            color: rgb(53 64 82);
            font-size: 13px;
            .rate {
              color: rgb(154 161 177);
              font-size: 12px;
            }
          }
        }
      }
    }
    .time-block::-webkit-scrollbar-track-piece {
      /* background-color: #f8f8f8; */
      background-color: #fff;
    }
    .time-block::-webkit-scrollbar {
      width: 6px;
    }
    .time-block::-webkit-scrollbar-thumb {
      background-color: #dddddd;
      border-radius: 100px;
    }
    .time-block::-webkit-scrollbar-thumb:hover {
      background-color: #bbb;
    }
    .do-recode {
      width: 100%;
      height: auto;
      padding: 15px 0;
      color: rgb(53 64 82);
      font-size: 16px;
    }
    .intro {
      width: 100%;
      height: auto;
      padding: 20px 15px;
      border-radius: 6px;
      backdrop-filter: blur(9.1px);
      box-shadow: 0 0 15px 0 rgba(53, 64, 82, 0.06);
      background-color: #fff;
      display: flex;
      flex-direction: column;
      .transaction-data {
        margin-top: 15px;
        display: flex;
        .position-button-blue {
          color: #3348f6;
          font-size: 14px;
          padding: 5px 40px;
          border-radius: 4px;
          background-color: #eff3f8;
          cursor: pointer;
        }
        .position-button {
          border: 0.5px solid rgb(194 199 208);
          font-size: 14px;
          border-radius: 4px;
          cursor: pointer;
          width: 80%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .opening-price-left {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            font-size: 14px;
            color: rgb(194 199 208);
          }
          .content {
            margin-top: 3px;
            font-size: 14px;
            color: rgb(53 64 82);
          }
        }
        .opening-price-middle {
          flex: 1;
          margin-left: 30px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            font-size: 14px;
            color: rgb(194 199 208);
          }
          .content {
            margin-top: 3px;
            font-size: 14px;
            color: rgb(53 64 82);
          }
        }
        .opening-price-right {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          .title {
            font-size: 14px;
            color: rgb(194 199 208);
          }
          .under-line {
            border-bottom: 0.5px solid rgb(194 199 208);
            text-decoration: none;
          }
          .content {
            margin-top: 3px;
            font-size: 14px;
            color: rgb(53 64 82);
          }
        }
      }
      .splite-line {
        width: 100%;
        height: 1px;
        background-color: rgb(238 238 238);
        margin-top: 13px;
        margin-bottom: 0px;
      }
      .income-data-status {
        margin-top: 3px;
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .income-data-num {
          font-weight: 500;
          font-size: 20px;
          color: rgb(53 64 82);
        }
        .income-color-red {
          color: rgb(240 74 90);
        }
        .income-color-green {
          color: rgb(0 188 160);
        }
      }
      .income-status {
        margin-top: 15px;
        width: 100%;
        height: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .income-num {
          font-size: 14px;
          color: rgb(194 199 208);
        }
      }
      .order-status {
        width: 100%;
        height: 22px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .symbol-name {
          font-size: 15px;
          color: rgb(53 64 82);
          font-weight: 500;
        }
        .transaction-multiple {
          margin-left: 10px;
          font-size: 15px;
        }
        .price-share {
          margin-left: auto;
          font-size: 15px;
          color: rgb(53 64 82);
          font-weight: 500;
          .last-price {
            margin-right: 5px;
          }
          .share-img {
            font-size: 20px;
            vertical-align: -9%;
          }
        }
      }
    }
  }
  .reCharge-dialog {
    .el-dialog__header {
      font-size: 20px;
      font-weight: 500;
      padding: 20px 30px 10px;
    }
    .el-dialog__body {
      padding: 10px 30px 30px;
    }
    .billImagePanel {
      margin-top: 10px;
      width: 100%;
      height: auto;
      padding: 20px 15px 0px;
      border-radius: 6px;
      box-shadow: 0 0 15px 0 rgba(53, 64, 82, 0.06);
      background-color: #fff;
      display: flex;
      flex-wrap: wrap;
      min-height: 160px;
      .no-data-panel {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .image-block {
        width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        min-height: 85px;
        .image-block-item {
          width: 70px;
          height: 70px;
          border-radius: 4.2px;
        }
      }
    }
    .billPanel {
      margin-top: 10px;
      width: 100%;
      height: auto;
      padding: 20px 15px 0px;
      border-radius: 6px;
      box-shadow: 0 0 15px 0 rgba(53, 64, 82, 0.06);
      background-color: #fff;
      display: flex;
      flex-direction: column;
      .el-timeline {
        .el-timeline-item {
          .el-timeline-item__tail {
            position: absolute;
            left: 4px;
            top: calc(50% - 20px);
            height: 100%;
            border-left: 2px solid #e4e7ed;
          }
          .el-timeline-item__node--normal {
            left: -1px;
            width: 12px;
            top: calc(50% - 20px);
            height: 12px;
            z-index: 10;
          }
        }
        .el-timeline-item:last-child {
          .el-timeline-item__tail {
            display: block;
            position: absolute;
            left: 4px;
            top: calc(50% - 20px);
            transform: translate(0%, -100%);
            height: 100%;
            border-left: 2px solid #e4e7ed;
          }
        }
      }
      .noActive {
        /* box-shadow: 0 0px 0px 0 rgb(0 0 0 / 10%);
          border: 0px; */
        .cardStep {
          .contentStep {
            .sub-title {
              color: #c2c3ce;
            }
            .sub-title-green {
              color: #31bd50;
            }
          }
        }
      }
      .cardStep {
        display: flex;
        flex-direction: column;
        .time {
          color: rgb(154 161 177);
          font-size: 13px;
        }
        .contentStep {
          margin-top: 5px;
          display: flex;
          justify-content: space-between;
          .sub-title {
            color: rgb(53 64 82);
            font-size: 14px;
          }
          .sub-content {
            color: rgb(53 64 82);
            font-size: 14px;
          }
        }
      }
      .image-block {
        display: flex;
      }
      .outer-bill-item {
        display: flex;
        flex-direction: column;
      }
      .bill-space {
        width: 100%;
        height: 0px;
        margin: 8px auto;
      }
      .bill-item {
        width: 100%;
        /* height: 20px; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        .bill-item-title {
          display: inline-block;
          width: 70px;
          font-size: 14px;
          color: #354052;
          flex-shrink: 0;
        }

        .bill-item-content {
          font-size: 13px;
          color: #9aa1b1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .image-qr-code {
            margin-left: 8px;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .copy-i {
            margin-left: 8px;
            width: 18px;
            height: 20px;
            font-size: 13px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }
    .tab-content {
      user-select: none;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .copy-account-msg {
        margin-top: 6px;
        color: #3348f6;
        padding: 5px 15px;
        border-radius: 20px;
        background-color: #eff3f8;
        cursor: pointer;
      }
      ul {
        display: flex;
      }
      .classify-area {
        margin-top: 5px;
        padding: 12px 15px 12px 15px;
        cursor: pointer;
        font-size: 15px;
        // height: 32px;
        line-height: 25px;
        color: #354052;
        position: relative;
        &:first-child {
          margin-left: -15px;
        }
        &.active {
          font-weight: 500;
          background-color: #ffffff;
          color: #354052;
        }
        .item-bar {
          position: absolute;
          width: 32%;
          height: 4px;
          border-radius: 2.5px;
          background-color: #e81b40;
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
        }
      }
    }
    .intro {
      width: 100%;
      height: auto;
      padding: 20px 15px;
      border-radius: 6px;
      backdrop-filter: blur(9.1px);
      box-shadow: 0 0 15px 0 rgba(53, 64, 82, 0.06);
      background-color: #fff;
      display: flex;
      flex-direction: column;
      .item-outer {
        display: flex;
        flex-direction: column;
      }
      .reCharge-space {
        width: 100%;
        height: 0px;
        margin: 8px 0;
      }
      .reCharge-item {
        width: 100%;
        height: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item-title {
          font-size: 14px;
          font-weight: 400;
          color: #9aa1b1;
        }
        .under-line {
          border-bottom: 0.5px solid rgb(194 199 208);
          text-decoration: none;
        }
        .item-value {
          font-size: 14px;
          color: #354052;
          .order-no {
            margin-right: 10px;
          }
          .copy-i {
            width: 15px;
            height: 15px;
            font-size: 15px;
            cursor: pointer;
            color: #c2c7d0;
          }
        }
      }
      .reCharge-status {
        width: 100%;
        height: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .status {
          font-size: 14px;
          color: #9aa1b1;
        }
        .status-yellow {
          color: #e58611;
        }
        .icon-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .reChargeIcon {
            width: 22px;
            height: 22px;
            margin-right: 5.5px;
          }
          .reChargeTitle {
            font-size: 16px;
            font-weight: 500;
            color: #354052;
          }
        }
      }
    }
  }
  .image-dialog.el-dialog {
    z-index: 0;
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
  }
  .image-dialog.el-dialog .el-dialog__body {
    flex: 1;
    overflow: auto;
    border-radius: 4px;
  }
  .image-dialog {
    .el-dialog__header {
      display: none;
      padding: 0;
    }
    .el-dialog__body {
      padding: 0 0 0 0;
      .dialog-image {
        width: 100%;
        // margin-bottom: -5px;
      }
    }
  }
  .details-dialog {
    .el-dialog__header {
      font-size: 20px;
      font-weight: 500;
      padding: 20px 30px 10px;
    }
    .el-dialog__body {
      padding: 10px 30px 30px;
    }
    .button-group {
      margin: 20px 25px 0 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      .btn-space {
        width: 10px;
        height: 40px;
      }
      .btn-cancel-disable {
        width: 160px;
        height: 40px;
        border-radius: 3px;
        font-size: 14px;
        text-align: center;
        color: #fff;
        background-color: #c2c7d0;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .btn-cancel {
        width: 160px;
        height: 40px;
        border-radius: 3px;
        border: solid 1px #c2c7d0;
        font-size: 14px;
        text-align: center;
        color: #354052;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .btn-confirm {
        width: 160px;
        height: 40px;
        border-radius: 3px;
        background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
        font-size: 14px;
        text-align: center;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .btn-confirm-common {
        width: 160px;
        height: 40px;
        border-radius: 3px;
        border-color: #c2c7d0;
        font-size: 14px;
        text-align: center;
        color: #354052;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
    .billPanel {
      margin-top: 10px;
      width: 100%;
      max-height: 460px;
      padding: 20px 15px 0px;
      border-radius: 6px;
      box-shadow: 0 0 15px 0 rgba(53, 64, 82, 0.06);
      background-color: #fff;
      display: flex;
      flex-direction: column;
      /* height: 80vh; */
      overflow-y: auto;
      &::-webkit-scrollbar-track-piece {
        /* background-color: #f8f8f8; */
        background-color: #fff;
        border-radius: 4px;
      }
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #dddddd;
        border-radius: 100px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #bbb;
      }
      .bill-space {
        width: 100%;
        height: 20px;
        /* margin: 20px auto; */
      }
      .bill-item {
        width: 100%;
        min-height: 18px;
        display: flex;
        .bill-item-title {
          display: inline-block;
          width: 90px;
          font-size: 14px;
          color: #9aa1b1;
          .under-line {
            border-bottom: 0.5px solid rgb(194 199 208);
            text-decoration: none;
          }
        }

        .bill-item-content {
          margin-left: 10px;
          display: inline-block;
          width: calc(100% - 90px - 40px);
          font-size: 14px;
          color: #354052;
          white-space: wrap;
          .stock {
            font-size: 14px;
            font-weight: 500;
          }
          .stock-code {
            font-size: 12px;
          }
        }

        .copy-i {
          width: 18px;
          margin-left: 20px;
          height: 18px;
          font-size: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
    .coinNum {
      width: 100%;
      height: 88px;
      padding: 20px 15px;
      border-radius: 6px;
      box-shadow: 0 0 15px 0 rgba(53, 64, 82, 0.06);
      background-color: #fff;
      display: flex;
      .coinImg {
        user-select: none;
        width: 48px;
        height: 48px;
      }
      .coinMsg {
        margin-left: 13px;
        width: calc(100% - 48px);
        display: flex;
        flex-direction: column;
        .coinCount {
          color: #f04a5a;
          font-size: 16px;
          font-weight: 500;
        }
        .coinStatus {
          margin-top: 6px;
          font-size: 13px;
          font-weight: 500;
          color: #c2c7d0;
        }
      }
    }
  }
  .trade-container {
    .header {
      border-radius: 4px;
      .content-title {
        background-color: #fff;
        position: relative;
        display: flex;
        box-shadow: 0 0 4px 0 rgb(0 0 0 / 7%);
        align-items: center;
        background-color: #ffffff;
        user-select: none;
        h2 {
          font-size: 26px;
          font-weight: 600;
          color: #354052;
          text-align: left;
          height: 80px;
          line-height: 80px;
          padding-left: 24px;
        }
      }
    }

    .bill-center-content {
      //   padding: 0px 20px;
      background: #ffffff;
      overflow: hidden;
      margin: 20px;
      min-height: calc(100vh - 200px);
      border-radius: 4px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
      .common-table-pages {
        user-select: none;
      }
      .filter-box {
        user-select: none;
        /* padding: 20px 0px 15px 0px; */
        margin: 15px 0px 10px 0px;
        .tenWid {
          width: 10px;
          height: 1px;
          display: inline-block;
          position: relative;
        }
        .twentyWid {
          width: 20px;
          height: 1px;
          display: inline-block;
          position: relative;
        }

        .btn-export {
          user-select: none;
          cursor: pointer;
          /* width: 120px; */
          padding: 10px 12px;
          border-radius: 3px;
          color: #354052;
          font-size: 14px;
          border: solid 1px #c2c7d0;
          box-sizing: border-box;
          float: right;
          .export-icon {
            color: #9aa1b1;
            margin-right: 4px;
          }
        }
        .el-input {
          width: 200px;

          .el-input__inner {
            border: solid 1px #e2e2e4;
            box-sizing: border-box;
            border-radius: 3px;
          }
        }
        .el-checkbox {
          margin-left: 20px;
          .el-checkbox__label {
            font-size: 14px;
            color: #354052;
            font-weight: 400;
          }
        }
        .selectStyle {
          width: 180px;
          .el-input.el-input--suffix {
            width: 100%;
          }
        }
      }

      .market-header {
        border-bottom: solid 1px #ecedf1;
        margin-bottom: 20px;
        ul {
          display: flex;
        }
        .classify-area {
          height: 40px;
          margin-top: 10px;
          font-size: 16px;
          font-weight: 500;
          color: #354052;

          padding: 0 10px;
          cursor: pointer;
          text-align: center;
          line-height: 32px;
          font-weight: normal;
          position: relative;
          user-select: none;
          &.active {
            font-weight: 500;
          }
          .item-bar {
            position: absolute;
            width: calc(100% - 20px);
            height: 3px;
            background-color: #e81b40;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
          }
        }
      }
      .content-header {
        width: 120px;
        height: 40px;
        margin-top: 10px;
        font-size: 20px;
        font-weight: 500;
        color: #354052;
      }
      .el-table {
        min-height: 570px;
      }
      .no-data-box {
        min-height: 520px;
      }
      .el-table__row {
        height: 50px !important;
        .cell {
          color: #354052;
          font-size: 14px;
          align-items: center;
        }
      }
      .coin-ing {
        width: 28px;
        height: 28px;
        margin-right: 8px;
      }
      .operation {
        user-select: none;
        font-size: 14px;
        color: #3348f6;
        font-weight: 400;
        cursor: pointer;
        // padding: 0px 0px 0px 20px;
        padding: 0px 0px 0px 0px;
      }
      .operation-ban {
        font-size: 14px;
        color: #9aa1b1;
        font-weight: 400;
        padding: 0px 0px 0px 0px;
      }
    }
  }
}
</style>
