<template>
  <div class="page-exchange">
    <div v-show="!isFullScreen" class="layout-block-first">
      <market
        ref="marketComponent"
        :marketList="marketList"
        @onAddOption="onMarketAddOption"
        @on-symbol-change="onSymbolChange"
      ></market>
    </div>
    <div
      class="layout-block-second"
      :style="{ height: isFullScreen ? '100%' : '' }"
    >
      <panel
        ref="panelComponent"
        :swap_symbol_id="swap_symbol_id"
        :tradePairArray="tradePairArray"
        :marketList="marketList"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
        @onAddOption="onAddOption"
      ></panel>
      <kline
        ref="klineComponent"
        :symbol="symbol"
        :coin-from="coinFrom"
        :coin-to="coinTo"
        :depthList="depthList"
        @full-screen="zoomKline"
      ></kline>
      <trade
        v-show="!isFullScreen"
        :swap_symbol_id="swap_symbol_id"
        :assetsInfo="assetsInfo"
        :marketList="marketList"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
        :from_item_id="from_item_id"
        :to_item_id="to_item_id"
        @on-trade-success="onTradeSuccess"
      ></trade>
    </div>
    <div v-show="!isFullScreen" class="layout-block-six">
      <div class="block-six-header">
        <ul>
          <li
            class="classify-area"
            :class="{ active: currIndex == item.value }"
            v-for="item of tabList"
            :key="item.value"
            @click="onAreaChange(item)"
          >
            {{ translateTitle(item.label) }}
            <i v-if="currIndex == item.value" class="item-bar"></i>
          </li>
        </ul>
      </div>
      <depth
        v-if="currIndex == 1"
        :showTitle="false"
        :depthList="depthList"
        :swap_symbol_id="swap_symbol_id"
        :marketList="marketList"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
      ></depth>
      <deal
        :showTitle="false"
        v-if="currIndex == 2"
        :orderbookList="orderbookList"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
      ></deal>
    </div>
    <div v-show="!isFullScreen" class="layout-block-third">
      <depth
        :depthList="depthList"
        :swap_symbol_id="swap_symbol_id"
        :marketList="marketList"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
      ></depth>
    </div>
    <div v-show="!isFullScreen" class="layout-block-fourth">
      <deal
        :orderbookList="orderbookList"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
      ></deal>
    </div>
    <div v-show="!isFullScreen" class="layout-block-five">
      <history-order
        :swap_symbol_id="swap_symbol_id"
        :exchangeCoinMap="coinInfoMap"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
        @on-symbol-change="onSymbolChangeV2"
        ref="historyOrder"
      ></history-order>
    </div>
  </div>
</template>
<script>
import Socket from "@/assets/js/socket.js";
import Kline from "./components/kline.vue";
import Depth from "./components/depth.vue";
import Deal from "./components/deal.vue";
import Market from "./components/market.vue";
import Trade from "./components/trade.vue";
import Panel from "./components/panel.vue";
import { mapGetters } from "vuex";
import store from "@/store/index";
import Cookies from "js-cookie";
import HistoryOrder from "./components/history-order.vue";
import { apiWsUrl, apiGetSpotList } from "@/model/exchange/index";
import { apiGetStaticTableInfo } from "@/model/settings";
import commonMixin from "@/components/common/commonMixin.vue";
import { VUE_APP_ID, VUE_UNION_STATION } from "@/assets/js/stationSetting";
export default {
  name: "Exchange",
  mixins: [commonMixin],
  components: {
    Deal,
    Kline,
    Depth,
    Market,
    Panel,
    Trade,
    HistoryOrder,
  },
  data() {
    return {
      tt: {},
      lockReconnect: false,
      tabList: [
        {
          value: "1",
          label: "盘口",
        },
        {
          value: "2",
          label: "实时成交",
        },
      ],
      currIndex: "1",
      wsContentTime: 0,
      symbol: this.$route.params.symbol || "btc_usdt",
      // symbol: "btc_usdt",
      coinFrom: "--",
      coinTo: "--",
      voucherInterval: null,
      coinInfoMap: {},
      exchangeCoinMap: {},
      tradePair: [],
      tradePairArray: [],
      marketList: [],
      tempMarkList: [],
      tempMarkListV2: [],
      apiMarketList: [],
      assetsInfo: {
        remain: {
          all_u_valuation: {
            usdt: "--",
          },
          list: [],
        },
      },
      assetsInfoList: [],
      orderbookList: [
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
      ],
      klineList: [],
      depthList: {
        a: [
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
        ],
        b: [
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
        ],
      },
      orderbookEData: [],
      CoinItemIdMap: {},
      swap_symbol_market: {},
      swap_symbol_id: 0,
      old_swap_symbol_id: 0,
      from_item_id: "",
      to_item_id: "",
      wsUrl: "",
      token: "",
      pageLoading: false,
      onload: false,
      marketInfoShow: {
        last: "",
        currency: { cny: "" },
      },
      isFullScreen: false,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
    }),
    title() {
      let title = "数字货币银行";

      let val =
        this.marketInfoShow.last +
        "  " +
        this.coinFrom.toUpperCase() +
        "/" +
        this.coinTo.toUpperCase() +
        "  " +
        this.translateTitle(title);

      return val;
    },
    // marketInfoShow() {
    //   let tempMarketInfo = {
    //     last: "",
    //     currency: { cny: "" },
    //   };
    //   this.marketList.forEach((ele) => {
    //     if (ele.symbol_id == this.swap_symbol_id) {
    //       tempMarketInfo = ele;
    //     }
    //   });
    //   return tempMarketInfo;
    // },
  },
  async created() {
    await this.getExchangeItemId();
    await this.getUrlSymbol();
    this.getTradePairList();
    // await this.getTradePairList();
    this.resolveCoin();
    let tempCookies = Cookies.get("topcredit_exchange_t");
    if (tempCookies == undefined) {
      await this.initURL();
      this.initWs();
    } else {
      this.token = Cookies.get("topcredit_exchange_t");
      await this.initURL();
      this.initWs();
    }
  },
  mounted() {
    var meta = null;
    var title = null;
    this.voucherInterval = setInterval(() => {
      document.title = this.title;
      // document.getElementById("chat-widget-container").style.display = "none"; //隐藏
    }, 500);
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  watch: {
    async userInfo(newValue, oldValue) {
      if (this.userInfo == null) {
        await this.initURL();
        this.initWs();
      } else {
        let tempUserInfo = sessionStorage.getItem("userInfo");
        if (tempUserInfo) {
          return;
        }
        this.token = Cookies.get("topcredit_exchange_t");
        await this.initURL();
        this.initWs();
      }
    },
  },
  methods: {
    zoomKline(isFull) {
      this.isFullScreen = isFull;
    },
    onAddOption() {
      this.$refs.marketComponent.getOptionList();
    },
    onMarketAddOption() {
      this.$refs.panelComponent.getOptionList();
    },
    onAreaChange(item) {
      this.currIndex = item.value;
    },

    onTradeSuccess(type) {
      this.$refs.historyOrder.reset(type);
    },
    getTradePairList() {
      apiGetSpotList().then(res => {
        if (res.status == 200) {
          let tempArr = res.data;
          tempArr.forEach(ele => {
            ele.lastV2 = Number(ele.last);
            ele.currency.usdV2 = Number(ele.currency.usd);
            ele.price_change_percentV2 = Number(ele.price_change_percent);
            for (const key in this.coinInfoMap) {
              if (ele.symbol_id == key) {
                ele["from_symbol"] = this.coinInfoMap[key].name
                  .split("_")[0]
                  .toUpperCase();
                ele["to_symbol"] = this.coinInfoMap[key].name
                  .split("_")[1]
                  .toUpperCase();
                ele["info"] = this.coinInfoMap[key];
                this.tempMarkList.push(ele);
              }
            }
          });

          this.marketList = this.tempMarkList;
        }
      });
    },
    async getUrlSymbol() {
      const { status, data } = await apiGetStaticTableInfo(55);
      if (status == 200) {
        let tempObj = {};
        for (const key in data) {
          if (
            data[key].symbol_type == 1 &&
            data[key].status == 1 &&
            data[key].is_show == 1
          ) {
            tempObj[key] = data[key];
          }
        }
        this.coinInfoMap = JSON.parse(JSON.stringify(tempObj));
        let tempSymbol = this.$route.params.symbol;
        if (!tempSymbol) {
          tempSymbol = "btc_usdt";
          this.symbol = "btc_usdt";
        }

        for (const key in this.coinInfoMap) {
          this.tradePairArray.push(
            this.coinInfoMap[key].name.replace("_", "/").toUpperCase()
          );
          if (
            this.coinInfoMap[key].name.toLowerCase() == tempSymbol.toLowerCase()
          ) {
            this.swap_symbol_id = this.coinInfoMap[key].symbol_id;
            this.from_item_id = this.coinInfoMap[key].coin_from;
            this.to_item_id = this.coinInfoMap[key].coin_to;
          }
        }
      }
    },
    async getExchangeItemId() {
      const { status, data } = await apiGetStaticTableInfo(13);
      if (status == 200) {
        this.CoinItemIdMap = JSON.parse(JSON.stringify(data));
      }
    },

    onSymbolChangeV2(params) {
      this.$refs.marketComponent.onSymbolChange(params, "1");
    },
    onSymbolChange(item) {
      if (this.swap_symbol_id == item.symbol_id) {
        return;
      }

      this.old_swap_symbol_id = this.swap_symbol_id;
      this.global.ws.sub({
        action: "Topic.unsub",
        data: {
          type: "order",
          symbol: this.swap_symbol_id,
        },
      });
      this.global.ws.sub({
        action: "Topic.sub",
        data: {
          type: "order",
          symbol: item.symbol_id,
        },
      });
      this.orderbookList = [
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
      ];

      this.global.ws.sub({
        action: "Topic.unsub",
        data: {
          type: "depth",
          symbol: this.swap_symbol_id,
        },
      });
      this.depthList = {
        a: [
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
        ],
        b: [
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
        ],
      };
      this.global.ws.sub({
        action: "Topic.sub",
        data: {
          type: "market",
          symbol: item.symbol_id,
        },
      });
      this.global.ws.sub({
        action: "Topic.sub",
        data: {
          type: "depth",
          symbol: item.symbol_id,
        },
      });
      this.$refs.klineComponent.onChangeSymbol(item);
      this.old_swap_symbol_id = this.swap_symbol_id;
      this.resolveCoin();
      this.swap_symbol_id = item.symbol_id;
      this.from_item_id = item.info.coin_from;
      this.to_item_id = item.info.coin_to;
    },
    uniqueArr(arr) {
      var hash = [];
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].E === arr[j].E) {
            ++i;
          }
        }
        hash.push(arr[i]);
      }
      return hash;
    },
    resolveCoin() {
      this.pageLoading = true;
      let tempSymbol = this.$route.params.symbol;
      if (!tempSymbol) {
        tempSymbol = "btc_usdt";
        this.swap_symbol_id = 22;
      }

      let trade_pair = tempSymbol.replace("_", "/").toUpperCase();
      if (this.tradePairArray.indexOf(trade_pair) == -1) {
        tempSymbol = "btc_usdt";
        this.swap_symbol_id = 22;
      }
      this.symbol = tempSymbol;
      const [coinFrom, coinTo] = tempSymbol.split("_");
      this.coinFrom = coinFrom;
      this.coinTo = coinTo;
      setTimeout(() => {
        this.pageLoading = false;
      }, 300);
    },

    async initURL() {
      let params = {
        __version: "3.4.3",
        // token: this.token,
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
      }
    },
    initWs() {
      const me = this;
      const option = {
        url: this.wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "kline",
            resolution: "15",
            symbol: me.swap_symbol_id,
          },
        },
        callback,
        onConnect,
        mounted,
      };

      let wsNew = new Socket(option).getInstance();
      me.global.setWs(wsNew);
      // 重新连接
      function onConnect() {
        if (me.lockReconnect) return;
        if (me.wsContentTime > 3) {
          window.location.reload();
        }
        me.lockReconnect = true;
        me.tt && clearTimeout(me.tt);
        me.tt = setTimeout(() => {
          me.wsContentTime++;
          console.log("ws重連");
          me.initWs();
          me.lockReconnect = false;
        }, 3000);
      }
      function mounted() {
        me.global.ws.sub({
          action: "Topic.sub",
          data: {
            type: "market",
            app_id: VUE_APP_ID,
            symbol: me.swap_symbol_id,
          },
        });
        me.global.ws.sub({
          action: "Topic.sub",
          data: {
            type: "marketM",
            app_id: VUE_APP_ID,
            // symbol: me.swap_symbol_id,
          },
        });
        me.global.ws.sub({
          action: "Topic.sub",
          data: {
            type: "depth",
            symbol: me.swap_symbol_id,
          },
        });
        me.global.ws.sub({
          action: "Topic.sub",
          data: {
            type: "order",
            symbol: me.swap_symbol_id,
          },
        });
        if (Cookies.get("topcredit_exchange_t")) {
          me.global.ws.sub({
            action: "Topic.sub",
            data: {
              type: "assets",
            },
          });
        }
      }
      function callback(data) {
        const { action } = data;
        if (action == "Pushdata.marketM") {
          if (data.data) {
            me.tempMarkListV2 = [];
            let tempArr = data.data;
            tempArr.forEach(ele => {
              ele.lastV2 = Number(ele.last);
              ele.currency.usdV2 = Number(ele.currency.usd);
              ele.price_change_percentV2 = Number(ele.price_change_percent);
              for (const key in me.coinInfoMap) {
                if (ele.symbol_id == key) {
                  ele["from_symbol"] = me.coinInfoMap[key].name
                    .split("_")[0]
                    .toUpperCase();
                  ele["to_symbol"] = me.coinInfoMap[key].name
                    .split("_")[1]
                    .toUpperCase();
                  ele["info"] = me.coinInfoMap[key];
                  me.tempMarkListV2.push(ele);
                }
              }
            });
            me.tempMarkListV2.forEach(ele => {
              if (ele.symbol_id == me.swap_symbol_id) {
                me.marketInfoShow = ele;
              }
            });
            me.marketList = me.tempMarkListV2;
          }
        }
        if (action == "Pushdata.kline") {
          window.resolveKlineData(data);
        }
        // 资产
        if (action == "Pushdata.assets") {
          if (data.data == null || data.data instanceof Array) {
            return;
          }
          me.assetsInfo = data.data;
        }
        if (action == "Pushdata.orderbook") {
          if (data.data) {
            if (!data.params.p || data.params.p == me.old_swap_symbol_id) {
              return;
            }
            me.orderbookList.unshift(...data.data);
            if (me.orderbookList.length > data.data.length) {
              me.orderbookList.splice(27, me.orderbookList.length + 1);
            }
            // if (me.orderbookList.length > 26) {
            //   me.orderbookList = me.orderbookList.slice(0, 26);
            // }
          }
        }
        if (action == "Pushdata.depth") {
          if (data.data) {
            if (!data.params.p || data.params.p == me.old_swap_symbol_id) {
              return;
            }
            let tempObj = data.data;
            if (tempObj.a.length > 0) {
              tempObj.c = 0;
              tempObj.d = 0;
            }
            me.depthList = tempObj;
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
.page-exchange {
  min-width: 1355px;
  @media screen and (min-width: 0px) and (max-width: 1555px) {
    .component-history-order {
      min-width: 1650px;
    }
  }
  @media screen and (min-width: 0px) and (max-width: 1784px) {
    .layout-block-six {
      display: block;
    }
    .layout-block-third,
    .layout-block-fourth {
      display: none;
    }
  }
  @media screen and (min-width: 1785px) {
    .layout-block-six {
      display: none;
    }
    .layout-block-third,
    .layout-block-fourth {
      display: block;
    }
  }
  margin: 0 auto;
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  background: #ecedf1;
  .layout-block-first {
    width: 310px;
    height: 790px;
    overflow: hidden;
    margin-right: 8px;
  }
  .layout-block-second {
    flex: 1;
    height: 790px;
    min-width: 700px;
  }
  .layout-block-third {
    max-width: 328px;
    background: #fff;
    height: 790px;
    margin-left: 8px;
    overflow: hidden;
  }

  .layout-block-fourth {
    max-width: 328px;
    background: #fff;
    height: 790px;
    margin-left: 8px;
  }
  .layout-block-five {
    width: 100%;
    overflow-x: auto;
    // height: 280px;
    flex-basis: 100%;
    background: #ecedf1;
  }
  .layout-block-six {
    max-width: 328px;
    background: #fff;
    height: 790px;
    margin-left: 8px;
    overflow: hidden;
    .block-six-header {
      background-color: #f5f5f7;
      ul {
        display: flex;
      }
      .classify-area {
        padding: 0 20px;
        cursor: pointer;
        height: 32px;
        font-size: 14px;
        font-weight: 600;
        color: #1f2533;
        line-height: 32px;
        position: relative;
        &.active {
          background-color: #ffffff;
          color: #000;
        }
        .item-bar {
          position: absolute;
          width: 24px;
          height: 2px;
          background-color: #1f2533;
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
        }
      }
    }
  }
}
</style>
