<template>
  <div class="component-panel">
    <div class="panel-message">
      <h1 class="coin-item panel-item">{{ coinFrom }}/{{ coinTo }}</h1>
      <!-- <div class="area-btn">主板</div> -->
      <div class="price-item panel-item">
        <p class="price DINPro" :class="colorClass">
          {{ marketInfoShow.last || "--" }}
          <span class="arrow" v-if="colorClass == 'green'">↑</span>
          <span class="arrow" v-else-if="colorClass == 'red'">↓</span>
        </p>
        <p class="price-cny">
          ≈ {{ systemCurrencyMaps[systemCurrency] }}
          <span class="DINPro">{{
            marketInfoShow.currency[systemCurrency] || "--"
          }}</span>
        </p>
      </div>
      <div class="increase-item panel-item">
        <p class="item">{{ translateTitle(`涨幅`) }}</p>
        <p class="value increase DINPro" :class="className">
          <span
            v-if="marketInfoShow.price_change_percent > 0"
            style="margin-right: -2px"
            >+</span
          >
          {{ marketInfoShow.price_change_percent || "--" }}%
        </p>
      </div>
      <div class="heigh-item panel-item">
        <p class="item">{{ translateTitle(`24H高`) }}</p>
        <p class="value DINPro">{{ marketInfoShow.high || "--" }}</p>
      </div>
      <div class="low-item panel-item">
        <p class="item">{{ translateTitle(`24H低`) }}</p>
        <p class="value DINPro">{{ marketInfoShow.low || "--" }}</p>
      </div>

      <div class="low-item panel-item">
        <p class="item">{{ translateTitle(`24H量`) }}</p>
        <p class="value">
          <span class="DINPro" v-if="Number(marketInfoShow.vol) > 0">
            {{ Number(marketInfoShow.vol).toFixed(2) }}</span
          >
          <span class="DINPro" v-else>--</span>
          {{ from_symbol_show }}
        </p>
      </div>
    </div>
    <div class="collet-item">
      <div
        class="border V2"
        v-if="selfOptionsList.indexOf(swap_symbol_id.toString()) > -1"
      >
        <img
          class="select-icon"
          src="../../../assets/img/exchange/active.png"
          @click="addOption(2)"
          alt=""
        />
      </div>
      <div class="border" v-else>
        <img
          class="select-icon"
          src="../../../assets/img/exchange/noActive.png"
          @click="addOption(1)"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import { VUE_UNION_STATION } from "@/assets/js/stationSetting";
import { apiAddOption, apiGetOptionList } from "@/model/markets/markets";
import * as Account from "@/model/user/account";
import { mapGetters } from "vuex";
import { systemCurrencyMaps } from "@/assets/js/keymap.js";
import commonMixin from "@/components/common/commonMixin.vue";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],
  props: {
    swap_symbol_id: {
      type: [String, Number],
      default: function() {
        return 22;
      },
    },
    tradePairArray: {
      type: Array,
      default: function() {
        return [];
      },
    },
    marketList: {
      type: Array,
      default: function() {
        return [];
      },
    },
    from_symbol: {
      type: [String, Number],
    },
    to_symbol: {
      type: [String, Number],
    },
  },
  data() {
    return {
      VUE_UNION_STATION,
      systemCurrencyMaps,
      coinFrom: "--",
      coinTo: "--",
      colorClass: "",
      selfOptionsList: [],
    };
  },
  async created() {
    this.getOptionList();
    this.getCoin();
  },
  methods: {
    routeSplicing,
    async getOptionList() {
      let { userInfo } = await Account.getUserInfo();
      if (!userInfo) {
        return;
      }
      apiGetOptionList().then(res => {
        if (res.status == 200) {
          if (JSON.stringify(res.data) != "{}" && res.data.digital) {
            this.selfOptionsList = res.data.digital;
          }
        }
      });
    },
    addOption(type) {
      if (this.userInfo) {
        let params = {
          symbol_id: this.swap_symbol_id,
          option: type,
          swap_type: 2,
        };
        apiAddOption(params).then(res => {
          if (res.status == 200) {
            this.getOptionList();
            this.$emit("onAddOption");
            this.$message({
              message:
                params.option == 1
                  ? this.translateTitle(`添加自选成功`)
                  : this.translateTitle(`删除自选成功`),
              type: params.option == 1 ? "success" : "success",
            });
          }
        });
      } else {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: { redirect: this.$route.fullPath },
        });
      }
    },
    getCoin() {
      let tempSymbol = this.$route.params.symbol;
      if (!tempSymbol) {
        tempSymbol = "btc_usdt";
      }

      let trade_pair = tempSymbol.replace("_", "/").toUpperCase();
      if (this.tradePairArray.indexOf(trade_pair) == -1) {
        tempSymbol = "btc_usdt";
      }

      let symbolPair = tempSymbol.split("_");
      this.coinFrom = symbolPair[0].toUpperCase();
      this.coinTo = symbolPair[1].toUpperCase();
    },
  },
  watch: {
    swap_symbol_id() {
      this.getCoin();
    },
    marketInfoShow(newValue, oldValue) {
      if (Number(newValue.last) - Number(oldValue.last) > 0) {
        this.colorClass = "green";
      } else if (Number(newValue.last) - Number(oldValue.last) < 0) {
        this.colorClass = "red";
      }
    },
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),
    from_symbol_show() {
      return this.from_symbol.toUpperCase() || "--";
    },
    to_symbol_show() {
      return this.to_symbol.toUpperCase() || "--";
    },
    marketInfoShow() {
      // this.pageLoading = true;
      let marketInfo = {
        last: "",
        currency: { cny: "" },
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.swap_symbol_id) {
          marketInfo = ele;
        }
      });
      // this.pageLoading = false;
      return marketInfo;
    },
    className() {
      let className = "";
      if (!this.marketInfoShow.price_change_percent) {
        className = "";
      } else if (this.marketInfoShow.price_change_percent > 0) {
        className = "green";
      } else if (this.marketInfoShow.price_change_percent < 0) {
        className = "red";
      }
      return className;
    },
  },
};
</script>
<style lang="scss">
.component-panel {
  height: 50px;
  background-color: #f5f5f7;
  display: flex;
  align-items: center;
  user-select: none;
  justify-content: space-between;
  .panel-message {
    display: flex;
    align-items: center;
  }
  .panel-item {
    display: inline-block;
  }
  .collet-item {
    margin-right: 10px;
    /* margin-top: -10px; */
    .border {
      width: 24px;
      height: 24px;
      border-radius: 2px;
      border: solid 1px #bec1c8;
      display: flex;
      justify-content: center;
      align-items: center;
      &.V2 {
        border: solid 1px #3348f6;
      }
      .select-icon {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }
  }
  .coin-item {
    min-width: 86px;
    font-size: 18px;

    font-weight: 500;
    margin-left: 15px;
    width: fit-content;
    margin-right: 10px;
    color: #354052;
    /* height: 39px; */
    vertical-align: middle;
  }
  .area-btn {
    width: 36px;
    height: 18px;
    line-height: 16px;
    border-radius: 2px;
    border: solid 1px #3348f6;
    box-sizing: border-box;
    text-align: center;
    font-size: 12px;
    color: #3348f6;
    font-weight: 500;
  }
  .price-item {
    // min-width: 110px;
    margin-left: 12px;
    .price {
      font-size: 16px;
      font-weight: bold;
      color: #354052;
      &.green {
        color: #03ad79;
      }
      &.red {
        color: #c61b3d;
      }
      .arrow {
        display: inline-block;
        font-size: 12px;
        width: 16px;
        height: 19px;
        &.V2 {
          color: #f5f5f7;
        }
      }
    }
    .price-cny {
      color: #7f8490;
      font-size: 12px;
    }
  }
  .increase-item {
    font-size: 12px;
    margin-left: 23px;
    width: 48px;
    .item {
      color: #7f8490;
    }
    .value {
      margin-top: 3px;
      &.green {
        color: #03ad79;
      }
      &.red {
        color: #c61b3d;
      }
    }
  }
  .heigh-item,
  .low-item {
    margin-left: 23px;
    font-size: 12px;
    .item {
      color: #7f8490;
    }
    .value {
      margin-top: 3px;
      color: #1f2533;
    }
  }
  .pair-wrap {
    .pair-list {
      display: flex;
      height: 26px;
      line-height: 26px;
      .pair-name {
        width: 200px;
      }
      .pair-price {
        width: 200px;
        text-align: right;
      }
      .pair-updown {
        width: 200px;
        text-align: right;
      }
    }
  }
}
</style>
