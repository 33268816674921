<template>
  <div></div>
</template>
<script>
// import { apiGetStaticTableInfo } from "@/model/settings";
import { translateTitle } from "@/assets/js/i18n";
import BigNumber from "bignumber.js";

export default {
  data() {
    return {
      projectName: "4e",
      projectFullName: "4e",
      projectEnglishName: "4e",
      projectTitle: "全球领先的数字资产服务平台",
    };
  },
  created() {
    this.projectEnglishName = "4e";
    this.projectFullName = "4e";
    this.projectTitle = "全球领先数字资产及衍生品交易平台";
  },
  methods: {
    translateTitle,
    // 向上取整
    ceilNumber(num, presicion) {
      if (!num) {
        num = 0;
      }
      var times = Math.pow(10, presicion);

      var nStr = new BigNumber(num);
      // 放大倍数
      var mulNum = nStr.times(times);
      mulNum = mulNum.toString();
      mulNum = parseFloat(mulNum);
      var ceilNum = Math.ceil(mulNum);

      var divNum = new BigNumber(ceilNum);
      divNum = divNum.div(times);
      // console.log(divNum);
      var rs = divNum.toFixed(presicion);
      return rs;
    },
    timestamp(created, formate = "yyyy-MM-dd HH:mm") {
      const stamp = `${created}000`;
      const date = new Date(Number(stamp));
      return window.DateFormat.format.date(date, formate);
    },
    // 千分符
    formatNumberRgx(num) {
      var parts = num.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
    // 切换分页
    onCurrentPageChange(val) {
      this.pageInfo.pageCurrent = val;
      this.initList();
    },
    // 分页数量
    onPagesizeChange(val) {
      this.pageInfo.pageSize = val;
      this.initList();
    },
    // 是否展开全部
    onToggle() {
      if (this.expandList.length == this.list.length) {
        this.expandList = [];
      } else {
        this.list.forEach(item => {
          this.expandList.push(item.user_id);
        });
      }
    },
    // 排序
    onSortChange(val) {
      this.queryForm.sort = val.prop;
      if (val.order == "ascending") {
        this.queryForm.order = "asc";
      } else if (val.order == "descending") {
        this.queryForm.order = "desc";
      } else {
        this.queryForm.order = "";
      }
    },
    // 搜索框展示折叠
    onToogleAdvanced() {
      this.advanced = !this.advanced;
      // if (this.advanced) {
      //   this.height = this.$baseTableHeight(343)
      // } else {
      //   this.height = this.$baseTableHeight(300)
      // }
      this.$nextTick(() => {
        this.handleHeight();
      });
    },

    // onSelectBlur(me, use, copy) {
    //   setTimeout(() => {
    //     me[use] = me[copy];
    //   }, 300);
    // },
    // onSelectFilter(val, me, use, copy, value, label) {
    //   me[use] = me[copy].filter((item) => {
    //     if (!val) {
    //       return true;
    //     }
    //     if (
    //       String(item[value]).indexOf(val) !== -1 ||
    //       String(item[label])
    //         .toUpperCase()
    //         .indexOf(val.toUpperCase()) !== -1
    //     ) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   });
    // },
  },
};
</script>
<style lang="scss"></style>
