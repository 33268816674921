<template>
  <div class="stand-inside-letter">
    <div class="letter-header">
      <div class="title">站内信</div>
    </div>
    <div class="letter-container">
      <div class="letter-content">
        <div class="letter-title">
          <div class="left">
            <span class="title">消息列表</span>
          </div>
          <div class="right">
            <el-checkbox class="" v-model="checked">隐藏已读</el-checkbox>
            <span @click="onReadAll" class="all-read">全部标为已读</span>
          </div>
        </div>
        <div
          @click="onReadMessage(item)"
          v-for="item in messageList"
          :key="item.message_id"
          :class="'letter-item' + ' ' + `${item.showDisplay ? 'show' : 'hide'}`"
        >
          <div class="item-header">
            <div class="left">
              <i v-if="item.status == 1" class="dot-red"></i>
              <span class="title">{{ item.title }}</span>
            </div>
            <div @click="item.showDisplay = !item.showDisplay" class="right">
              <span class="details">{{
                item.showDisplay ? "收起" : "详情"
              }}</span>
              <i class="iconfont iconsize16-1"></i>
            </div>
          </div>
          <div v-if="!showDisplay" class="hide-content">
            <div class="content">
              {{ item.content }}
            </div>
            <div class="time">{{ item.create_time }}</div>
          </div>
          <div v-else class="show-content">
            <div class="content">
              {{ item.content }}
            </div>
            <div class="time">{{ item.create_time }}</div>
          </div>
        </div>
        <no-data class="no-data" v-show="messageList.length == 0" />
        <div class="common-table-pages" v-if="messageList.length > 0">
          <p>
            {{ translateTitle("共") }}
            <span>{{ pageInfo.pageCount }}</span>
            {{ translateTitle("页") }}
          </p>
          <el-pagination
            class="mt20"
            background
            layout="prev, pager, next"
            :total="pageInfo.pageTotal"
            :page-size="pageInfo.pageSize"
            :current-page.sync="pageInfo.pageCurrent"
            @current-change="onCurrentPageChange"
          ></el-pagination>
        </div>
        <!-- <el-pagination
          layout="total, prev, pager, next,jumper"
          :page-size="pageInfo.pageSize"
          :total="pageInfo.pageTotal"
          :current-page.sync="pageInfo.pageCurrent"
          @current-change="onPageChange"
          background
        /> -->
      </div>
    </div>
  </div>
</template>
<script>
import { apiReadMessage, apiGetMessageList } from "@/model/letter/index.js";
import { parseTime } from "@/utils/index.js";
import NoData from "@/components/common/NoData";
import { translateTitle } from "@/assets/js/i18n";
export default {
  data() {
    return {
      showDisplay: false,
      checked: false,
      pageLoading: false,
      messageList: [],
      pageInfo: {
        pageCount: 1,
        pageCurrent: 1,
        pageSize: 8,
        pageTotal: 6,
      },
    };
  },
  components: {
    NoData,
  },
  created() {
    this.getMessageList();
  },
  mounted() {
    this.startTimer();
  },
  computed: {
    textSwitch() {
      if (this.showDisplay == false) {
        return "详情";
      } else {
        return "收起";
      }
    },
  },

  methods: {
    translateTitle,
    onClickDetails() {},
    startTimer() {
      this.getMessageList().then(() => {
        const timer = setTimeout(() => {
          this.startTimer();
        }, 5000);
        this.$once("hook:beforeDestroy", () => {
          clearTimeout(timer);
        });
      });
    },

    async getMessageList() {
      const req = {
        page: this.pageInfo.pageCurrent,
        pageSize: this.pageInfo.pageSize,
      };
      const { status, data } = await apiGetMessageList(req);
      if (status == 200) {
        data.list.forEach(ele => {
          ele.showDisplay = false;
          ele.create_time = parseTime(ele.create_time);
          ele.conLength = ele.content.length;
        });
        this.messageList = data.list;
        this.pageInfo = data.pageInfo;
      }
    },
    async onReadMessage(item) {
      const req = {
        message_id: item.message_id,
      };
      const { status, data } = await apiReadMessage(req);
      if (status == 200) {
        item.status = 2;
      }
    },
    async onReadAll() {
      const req = {
        message_id: -1,
      };
      const { status, data } = await apiReadMessage(req);
      if (status == 200) {
        this.getMessageList();
      }
    },
    onCurrentPageChange() {
      this.getMessageList();
    },
  },
};
</script>
<style lang="scss">
.stand-inside-letter {
  .letter-header {
    height: 70px;
    width: 100%;
    background: url("../../assets/img/letter-header.png") repeat;
    background-size: 100% 100%;
    display: flex;
    padding: 30px 0px 13px 0px;
    justify-content: center;
    .title {
      width: 1200px;

      font-size: 19px;
      font-weight: 600;
      color: #ffffff;
    }
  }
  .letter-container {
    display: flex;
    justify-content: center;
    .letter-content {
      width: 1200px;
      height: 1040px;
      margin: 21px 295px 20px 296px;
      border-radius: 2px;
      border: solid 1px #e1e2e6;
      background-color: #ffffff;
      position: relative;
      .no-data-box {
        height: calc(100vh - 360px);
      }
      .letter-title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 48px;
        padding: 13px 25px 13px 24px;
        border-radius: 2px;
        border: solid 1px #e1e2e6;
        background-color: #f3f7fc;
        box-sizing: border-box;
        .left {
          .title {
            font-size: 16px;
            font-weight: 500;
            color: #080c34;
          }
        }
        .right {
          .el-checkbox {
            margin-right: 27px;
            .el-checkbox__label {
              font-size: 12px;
            }
            .el-checkbox__inner {
              width: 12px;
              height: 12px;
            }
            .is-checked + .el-checkbox__label {
              color: #3348f6;
            }
            .is-checked .el-checkbox__inner {
              background-color: #3348f6;
              border-color: #3348f6;
            }
          }
          .all-read {
            color: #3348f6;
            cursor: pointer;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
      .letter-item {
        padding: 19px 25px 17px 24px;
        // height: auto;
        cursor: pointer;
        border-bottom: 1px solid #edeeef;
        &.show {
          height: auto;
          background-color: #f3f7fc;
        }
        &.hide {
          height: 103px;
        }
        .item-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            font-size: 14px;
            font-weight: 500;
            position: relative;
            .dot-red {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background: #c61b3d;
              position: absolute;
              left: -10px;
              top: 7px;
            }
            .title {
              color: #080c34;
            }
          }
          .right {
            cursor: pointer;
            color: #3348f6;
            .details {
              font-size: 14px;
            }
          }
        }

        .content {
          margin-top: 5px;
          width: 980px;
          height: auto;
          font-size: 14px;
          font-weight: 500;
          color: #7f8490;
        }
        .time {
          margin-top: 5px;
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #7f8490;
        }
      }
    }
  }
  .el-pagination {
    position: absolute;
    bottom: 15px;
    right: 25px;
    display: flex;
    justify-content: flex-end;
    height: 60px;
    align-items: center;
  }
}
</style>
