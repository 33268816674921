<template>
  <div class="currency-based-contract-component-trade">
    <div class="trade-header">
      {{ translateTitle("币本位合约") }}
      <span class="multiple-num" @click="onMultipleNumClick">
        {{ translateTitle("杠杆") }} {{ multipleNum }} X
        <img src="../../../assets/img/arrow-down_clear.png" alt="" />
      </span>
    </div>
    <div class="trade-content">
      <div class="left-item">
        <div class="item-header" v-if="userInfo">
          {{ translateTitle("可用:") }}
          <span>{{ assetsInfoShow }}</span>
          {{ from_symbol.toUpperCase() }}
        </div>
        <div class="item-header V2" v-else></div>
        <div class="trade-item">
          <div class="trade-type">
            <div
              class="item-tabs"
              :class="{ active: currChainValue == item.value }"
              @click="onChainChange(item.value)"
              v-for="(item, index) in tabsOptions"
              :key="index"
            >
              {{ translateTitle(item.label) }}
            </div>
          </div>
          <!-- <InputNumber
            v-if="currChainValue == '止盈止损'"
            class="sell-price"
            v-model="buyTriggerPrice"
            autocomplete="off"
            maxlength="10"
            decimals="8"
            frontSuffix="触发价"
            suffix="USDT"
            ref="sellPrice"
          ></InputNumber> -->
          <!--市价买入价 -->
          <!-- 1 -->
          <InputNumber
            v-if="currChainValue == '1'"
            class="buy-price market-price"
            autocomplete="new-password"
            maxlength="10"
            decimals="8"
            disabled
            :placeholder="translateTitle('以市场上最优价格买入')"
            :frontSuffix="translateTitle('买入价格')"
            ref="buyPrice"
            :suffix="to_symbol.toUpperCase()"
          ></InputNumber>

          <!--市价交易额 -->
          <!-- 2 -->
          <InputNumber
            v-if="currChainValue == '1'"
            class="buy-price"
            @input="onBuyAmountChange"
            v-model="buyAmount"
            autocomplete="new-password"
            maxlength="10"
            :decimals="settlement_precision"
            :frontSuffix="translateTitle('买入金额')"
            :suffix="from_symbol.toUpperCase()"
            ref="buyPrice"
          ></InputNumber>
          <!--限价买入量 -->

          <!-- 3 -->
          <span v-else>
            <InputNumber
              class="buy-price"
              v-model="buyPriceSelf"
              @input="onBuyChangeV2('1')"
              autocomplete="new-password"
              maxlength="10"
              :decimals="price_precision"
              :frontSuffix="translateTitle('买入价格')"
              ref="buyPrice"
              :suffix="to_symbol.toUpperCase()"
            ></InputNumber>
            <!-- 4 -->
            <InputNumber
              class="buy-price"
              v-model="buyAmountSelf"
              @input="onBuyChangeV2('2')"
              autocomplete="new-password"
              maxlength="10"
              :decimals="settlement_precision"
              :frontSuffix="translateTitle('买入金额')"
              :suffix="from_symbol.toUpperCase()"
              ref="buyPrice"
            ></InputNumber>
          </span>

          <div class="block">
            <el-slider
              @input="onBuySliderChange"
              v-model="buySliderValue"
              :disabled="!userInfo"
              :format-tooltip="formatTooltip"
              :step="1"
              :marks="marks"
            >
            </el-slider>
          </div>
          <!-- 5 -->
          <div v-if="currChainValue == '1'" class="buy-num-content">
            <span class="buy-num-text">
              {{ translateTitle("预计开仓数量") }}
            </span>
            <span class="buy-num">
              {{ tradingVolume || "--" }} {{ from_symbol.toUpperCase() }}</span
            >
          </div>
          <div v-if="currChainValue == '2'" class="buy-num-content">
            <span class="buy-num-text">
              {{ translateTitle("预计开仓数量") }}
            </span>
            <span class="buy-num">
              {{ tradingVolumeSelf || "--" }}
              {{ from_symbol.toUpperCase() }}</span
            >
          </div>
          <!-- <InputNumber
            v-if="currChainValue == '1'"
            class="buy-price"
            v-model="tradingVolume"
            @input="onBuyTotalChange"
            autocomplete="new-password"
            maxlength="10"
            :decimals="settlement_precision"
            :frontSuffix="translateTitle('买入数量5')"
            :suffix="from_symbol.toUpperCase()"
            ref="buyVolume"
          ></InputNumber> -->
          <!-- 6 -->
          <!-- <InputNumber
            v-if="currChainValue == '2'"
            class="buy-price"
            v-model="tradingVolumeSelf"
            @input="onBuyChangeV3"
            autocomplete="new-password"
            maxlength="10"
            :frontSuffix="translateTitle('买入数量6')"
            :decimals="settlement_precision"
            :suffix="from_symbol.toUpperCase()"
            ref="buyPrice"
          ></InputNumber> -->

          <div class="trade-btn">
            <el-button
              @click="onTrade(1)"
              :loading="buyBtnLoading"
              v-if="userInfo"
              class="trade-buy btn"
            >
              {{ translateTitle("买涨") }}
              {{ from_symbol.toUpperCase() }}</el-button
            >
            <span v-else class="trade-buy btn login">
              <span @click="goLogin(1)" class="common-operate"
                >{{ translateTitle("登录") }}
              </span>
              <span class="or-text"> {{ translateTitle("或") }} </span>
              <span @click="goLogin(2)" class="common-operate">{{
                translateTitle("注册")
              }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="right-item">
        <!-- <div class="item-header" v-if="userInfo">
          可用: <span>{{ assetsInfoShowSell }}</span>
          {{ from_symbol.toUpperCase() }}
        </div> -->
        <div class="item-header V3" v-if="userInfo"></div>
        <div class="item-header V2" v-else></div>
        <div class="trade-item">
          <div class="trade-type">
            <div
              class="item-tabs"
              :class="{ active: currChainValue == item.value }"
              @click="onChainChange(item.value)"
              v-for="(item, index) in tabsOptions"
              :key="index"
            >
              {{ translateTitle(item.label) }}
            </div>
          </div>
          <!-- <InputNumber
            v-if="currChainValue == '止盈止损'"
            class="sell-price"
            v-model="sellTriggerPrice"
            autocomplete="off"
            maxlength="10"
            decimals="8"
            frontSuffix="触发价"
            suffix="USDT"
            ref="sellPrice"
          ></InputNumber> -->
          <!-- 7 -->
          <InputNumber
            v-if="currChainValue == '1'"
            class="buy-price market-price"
            autocomplete="new-password"
            maxlength="10"
            decimals="2"
            disabled
            :placeholder="translateTitle('以市场上最优价格卖出')"
            :frontSuffix="translateTitle('卖出价格')"
            ref="buyPrice"
            :suffix="to_symbol.toUpperCase()"
          ></InputNumber>
          <!-- 8 -->
          <InputNumber
            v-if="currChainValue == '2'"
            class="sell-price"
            v-model="sellPrice"
            @input="onSellNumChange"
            autocomplete="new-password"
            maxlength="10"
            :decimals="price_precision"
            :frontSuffix="translateTitle('卖出价格')"
            ref="sellPrice"
            :suffix="to_symbol.toUpperCase()"
          ></InputNumber>
          <!-- 9 -->
          <InputNumber
            v-if="currChainValue == '1'"
            class="sell-price"
            v-model="sellNum"
            @input="onSellNumChangeMarket"
            autocomplete="new-password"
            maxlength="10"
            :decimals="settlement_precision"
            :frontSuffix="translateTitle('卖出金额')"
            :suffix="from_symbol.toUpperCase()"
            ref="sellPrice"
          ></InputNumber>
          <!-- 12 -->
          <InputNumber
            v-if="currChainValue == '2'"
            class="sell-price"
            v-model="sellNumSelf"
            @input="onSellNumChange"
            autocomplete="new-password"
            maxlength="10"
            :decimals="settlement_precision"
            :frontSuffix="translateTitle('卖出金额')"
            :suffix="from_symbol.toUpperCase()"
            ref="sellPrice"
          ></InputNumber>
          <div class="block">
            <el-slider
              v-model="sellSliderValue"
              @input="onSellSliderChange"
              :disabled="!userInfo"
              :format-tooltip="formatTooltip"
              :step="1"
              :marks="marks"
            >
            </el-slider>
          </div>
          <div v-if="currChainValue == '1'" class="buy-num-content">
            <span class="buy-num-text">
              {{ translateTitle("预计开仓数量") }}
            </span>
            <span class="buy-num">
              {{ sellTransactionAmount || "--" }}
              {{ from_symbol.toUpperCase() }}</span
            >
          </div>
          <div v-if="currChainValue == '2'" class="buy-num-content">
            <span class="buy-num-text">
              {{ translateTitle("预计开仓数量") }}
            </span>
            <span class="buy-num">
              {{ sellTransactionAmountSelf || "--" }}
              {{ from_symbol.toUpperCase() }}</span
            >
          </div>
          <!-- 10 -->
          <!-- <InputNumber
            v-if="currChainValue == '1'"
            class="buy-price"
            @input="onSellTotalChange"
            v-model="sellTransactionAmount"
            autocomplete="new-password"
            maxlength="10"
            :decimals="settlement_precision"
            :frontSuffix="translateTitle('卖出数量')"
            :suffix="from_symbol.toUpperCase()"
            ref="buyVolume"
          ></InputNumber> -->

          <!-- 11 -->
          <!-- <InputNumber
            v-if="currChainValue == '2'"
            class="buy-price"
            v-model="sellTransactionAmountSelf"
            @input="onSellNumChangeSelf"
            autocomplete="new-password"
            maxlength="10"
            :decimals="settlement_precision"
            :frontSuffix="translateTitle('卖出数量')"
            :suffix="from_symbol.toUpperCase()"
            ref="buyPrice"
          ></InputNumber> -->

          <div class="trade-btn">
            <el-button
              @click="onTrade(2)"
              :loading="sellBtnLoading"
              v-if="userInfo"
              class="trade-sell btn"
            >
              {{ translateTitle("买跌") }}
              {{ from_symbol.toUpperCase() }}</el-button
            >
            <span v-else class="trade-sell btn login">
              <span @click="goLogin(1)" class="common-operate">{{
                translateTitle("登录")
              }}</span>
              <span class="or-text"> {{ translateTitle("或") }} </span>
              <span @click="goLogin(2)" class="common-operate">{{
                translateTitle("注册")
              }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputNumber from "@/components/common/InputNumber";
import { mapGetters } from "vuex";
import { apiGetAssets } from "@/model/exchange/index";
import {
  apiOpenPosition,
  apiPlanOrder,
} from "@/model/currencyBasedContract/order";
import { apiGetStaticTableInfo } from "@/model/settings";
import BigNumber from "bignumber.js";
import { showMessage } from "@/components/dialog/message";
import Dispatcher from "@/assets/js/SocketDispatcher";
import commonMixin from "@/components/common/commonMixin.vue";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  components: { InputNumber },
  mixins: [commonMixin],
  props: {
    assetsInfoList: {
      type: Array,
      default: function() {
        return [];
      },
    },
    marketList: {
      type: Array,
      default: function() {
        return [];
      },
    },
    assetsInfo: {
      type: Object,
      default: function() {
        return {
          remain: {
            all_u_valuation: {
              usdt: "--",
            },
            list: [],
          },
        };
      },
    },
    swap_symbol_id: {
      type: [String, Number],
      default: function() {
        1010;
      },
    },
    from_symbol: {
      type: [String, Number],
    },
    to_symbol: {
      type: [String, Number],
    },
    from_item_id: {
      type: [String, Number],
    },
    to_item_id: {
      type: [String, Number],
    },
  },
  computed: {
    ...mapGetters({
      language: "user/language",
      userInfo: "user/userInfo",
    }),
    // 现有U资产
    assetsInfoShow() {
      let tempObj = "0";
      if (
        JSON.stringify(this.assetsInfo) != "{}" &&
        this.assetsInfo.remain.list.length > 0
      ) {
        this.assetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.from_item_id) {
            tempObj = ele.over;
          }
        });
      } else if (
        JSON.stringify(this.apiAssetsInfo) != "{}" &&
        this.apiAssetsInfo.remain.list.length > 0
      ) {
        this.apiAssetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.from_item_id) {
            tempObj = ele.over;
          }
        });
      } else {
        tempObj = "0";
      }
      return tempObj;
    },
    // 市场现在交易价格
    marketInfoShow() {
      let marketInfo = {
        last: "",
        currency: { cny: "" },
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.swap_symbol_id) {
          marketInfo = ele;
        }
      });
      return marketInfo;
    },
  },
  watch: {
    swap_symbol_id() {
      if (this.swap_symbol_id == "") {
        return;
      }
      let tempMultipleNum = JSON.parse(
        localStorage.getItem("delivery-multiple-num")
      );
      for (const key in tempMultipleNum) {
        if (key == this.swap_symbol_id) {
          this.multipleNumBtn = true;
          this.multipleNum = Number(tempMultipleNum[key]);
          setTimeout(() => {
            this.multipleNumBtn = false;
          }, 1000);
        }
      }
      if (this.multipleNumBtn == false) {
        this.multipleNum = 20;
      }

      this.buySliderValue = 0;
      this.buyNum = "";
      this.buyPrice = "";
      this.buyPriceSelf = "";
      this.buyAmountSelf = "";
      this.buyAmount = "";
      this.sellNumSelf = "";
      this.tradingVolume = "";
      this.tradingVolumeSelf = "";
      this.sellSliderValue = 0;
      this.sellPrice = "";
      this.sellNum = "";
      this.number_precision = this.coinInfoMap[
        this.swap_symbol_id
      ].number_precision;
      this.open_position_rate = this.coinInfoMap[
        this.swap_symbol_id
      ].open_position_rate;
      this.price_precision = this.coinInfoMap[
        this.swap_symbol_id
      ].price_precision;
      this.contract_value = this.coinInfoMap[
        this.swap_symbol_id
      ].contract_value;
      this.fee_rate = this.coinInfoMap[this.swap_symbol_id].fee_rate;
      this.settlement_precision = this.coinInfoMap[
        this.swap_symbol_id
      ].settlement_precision;
    },
  },
  data() {
    return {
      coinInfoMap: {},
      multipleNumBtn: false,
      currChainValue: "1",
      buySliderValue: 0,
      multipleNum: 20,
      sellSliderValue: 0,
      marketPrice: "",
      buyPrice: "",
      buyPriceSelf: "",
      sellNumSelf: "",
      sellPrice: "",
      buyNum: "",
      buyAmount: "", //
      buyAmountSelf: "", //现价买入金额 U
      sellNum: "",
      buyTriggerPrice: "",
      sellTriggerPrice: "",
      number_precision: "",
      settlement_precision: "",
      open_position_rate: "",
      price_precision: "",
      contract_value: "",
      fee_rate: "",
      buyBtnLoading: false,
      sellBtnLoading: false,
      tradingVolume: "", // 买入交易量
      tradingVolumeSelf: "", // 限价买入交易数量
      transactionAmount: "", // 买入交易额
      sellTransactionAmount: "", // 卖出交易额
      sellTransactionAmountSelf: "", // 限价卖出交易额
      // from_item_id: "",
      // to_item_id: "",
      marks: {
        25: "25%",
        50: "50%",
        75: "75%",
      },
      apiAssetsInfo: {},
      coinInfoList: [],
      tabsOptions: [
        {
          label: "市价",
          value: 1,
        },
        {
          label: "限价",
          value: 2,
        },
        // {
        //   label: "止盈止损",
        // },
      ],
      inputControl: false,
    };
  },
  async created() {
    await this.getStaticTableInfo(58);
    await this.getAssets();
    Dispatcher.addEventListener("onPriceClick", this.onPriceClick, this);
  },
  mounted() {},
  methods: {
    routeSplicing,
    async getAssets() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        type: "all",
      };
      const { status, data } = await apiGetAssets(params);
      if (status == 200) {
        this.apiAssetsInfo = data;
      }
    },
    onMultipleNumClick() {
      this.$emit("onMultipleNumClick", this.multipleNum);
    },
    onPriceClick(row) {
      if (this.currChainValue == "2") {
        this.buyPriceSelf = row[0];
        this.onBuyChangeV2("1");
        this.sellPrice = row[0];
        this.onSellNumChange();
      }
    },
    setMultipleNum(value) {
      this.multipleNum = value;
      this.onBuyChangeV2("2");
      this.onSellNumChangeMarket();
      this.onBuyAmountChange(this.buyAmount);
      this.onSellNumChange();
    },
    // 买入滑块变化
    onBuySliderChange(value) {
      this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
      if (this.currChainValue == "1") {
        if (value && !this.inputControl) {
          // 买入交易额
          this.buyAmount = ((value / 100) * this.assetsInfoShow).toFixed(
            this.settlement_precision
          );

          let tempMultipleNum = new BigNumber(this.multipleNum);
          let tempInput = new BigNumber(this.buyAmount);
          let tempContractValue = new BigNumber(this.contract_value);
          let tempFeeRate = new BigNumber(this.fee_rate);
          let one = new BigNumber(1);
          let tenThousand = new BigNumber(10000);

          // 开仓手续费 = 保证金 × 杠杆倍数 × 开仓手续费率 open_position_rate

          let bigMarketPrice = new BigNumber(
            JSON.parse(JSON.stringify(this.marketPrice))
          );
          // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
          // 持仓张数 = 开仓价 × 开仓本金 / (1 + 手续费率 ×杠杆倍数)/单张仓位价值 * 杠杆倍数

          let tempValV2 = bigMarketPrice
            .times(tempInput)
            .div(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum)))
            .div(tempContractValue)
            .times(tempMultipleNum);
          let tempVal3 = new BigNumber(tempValV2.toNumber());
          // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
          let tempVolume = tempVal3
            .times(tempContractValue)
            .div(bigMarketPrice)
            .toString();

          if (this.settlement_precision > 0) {
            this.tradingVolume = tempVolume.substring(
              0,
              tempVolume.indexOf(".") + this.settlement_precision + 1 // +1 保留小数点位数
            );
          } else {
            this.tradingVolume = tempVolume.substring(
              0,
              tempVolume.indexOf(".")
            );
          }
        } else if (value == 0 && !this.inputControl) {
          this.buyAmount = "";
          this.tradingVolume = "";
        }
      } else if (this.currChainValue == "2") {
        // buyPriceSelf  限价买入价3 buyAmountSelf 现价买入金额4   tradingVolumeSelf// 现价买入数量6

        if (
          this.buyPriceSelf != "" &&
          this.buyPriceSelf != 0 &&
          !this.inputControl
        ) {
          // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
          // 持仓张数 = 开仓价 × 开仓本金 / (1 + 手续费率 ×杠杆倍数)/单张仓位价值 * 杠杆倍数

          // 买入交易额
          let tempPrice = new BigNumber(this.buyPriceSelf); // 自定义买入价格
          this.buyAmountSelf = ((value / 100) * this.assetsInfoShow).toFixed(
            this.settlement_precision
          );
          let tempInput = new BigNumber(this.buyAmountSelf);
          let tempFeeRate = new BigNumber(this.fee_rate);
          let one = new BigNumber(1);
          let tenThousand = new BigNumber(10000);
          let tempMultipleNum = new BigNumber(this.multipleNum);
          let tempContractValue = new BigNumber(this.contract_value);
          let tempValV2 = tempPrice
            .times(tempInput)
            .div(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum)))
            .div(tempContractValue)
            .times(tempMultipleNum);
          let tempVal3 = new BigNumber(tempValV2.toNumber());
          // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
          let tempVolumeSelf = tempVal3
            .times(tempContractValue)
            .div(tempPrice)
            .toString();
          if (this.settlement_precision > 0) {
            this.tradingVolumeSelf = tempVolumeSelf.substring(
              0,
              tempVolumeSelf.indexOf(".") + this.settlement_precision + 1
            ); // +1 保留小数点位数
          } else {
            this.tradingVolumeSelf = tempVolumeSelf.substring(
              0,
              tempVolumeSelf.indexOf(".")
            );
          }
        }
      }
    },
    // 卖出滑块变化
    onSellSliderChange(value) {
      // sellNum  卖出金额9  sellTransactionAmount  卖出数量10
      this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));

      if (this.currChainValue == "1") {
        let tempPrice = new BigNumber(this.marketPrice);
        let tempMultipleNum = new BigNumber(this.multipleNum);
        if (value && !this.inputControl) {
          this.sellNum = (value * 0.01 * this.assetsInfoShow).toFixed(
            this.settlement_precision
          );

          let tempInput = new BigNumber(this.sellNum);
          let tempFeeRate = new BigNumber(this.fee_rate);
          let one = new BigNumber(1);
          let tenThousand = new BigNumber(10000);
          let tempContractValue = new BigNumber(this.contract_value);
          let tempValV2 = tempPrice
            .times(tempInput)
            .div(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum)))
            .div(tempContractValue)
            .times(tempMultipleNum);
          let tempVal3 = new BigNumber(tempValV2.toNumber());
          // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
          let tempSellAmount = tempVal3
            .times(tempContractValue)
            .div(tempPrice)
            .toString();
          if (this.settlement_precision > 0) {
            this.sellTransactionAmount = tempSellAmount.substring(
              0,
              tempSellAmount.indexOf(".") + this.settlement_precision + 1
            );
          } else {
            this.sellTransactionAmount = tempSellAmount.substring(
              0,
              tempSellAmount.indexOf(".")
            );
          }
        } else if (value == 0 && !this.inputControl) {
          this.sellNum = "";
          this.sellTransactionAmount = "";
        }
      } else if (this.currChainValue == "2") {
        //  sellPrice 卖出价格8 sellNumSelf 卖出金额12  sellTransactionAmountSelf  卖出数量11
        if (this.sellPrice != "" && this.sellPrice != 0) {
          if (value && !this.inputControl) {
            let tempSellPrice = new BigNumber(this.sellPrice);
            let tempValue = new BigNumber(value);
            let tempAssetsInfoShow = new BigNumber(this.assetsInfoShow);
            let zeroone = new BigNumber(0.01);
            this.sellNumSelf = tempValue
              .times(zeroone)
              .times(tempAssetsInfoShow)
              .toFixed(this.number_precision);
            // console.log(this.sellNumSelf);
            // this.sellNumSelf = value * 0.01 * this.assetsInfoShow;
            let tempMultipleNum = new BigNumber(this.multipleNum);

            let tempInput = new BigNumber(this.sellNumSelf);
            let tempFeeRate = new BigNumber(this.fee_rate);
            let one = new BigNumber(1);
            let tenThousand = new BigNumber(10000);
            let tempContractValue = new BigNumber(this.contract_value);
            let tempValV2 = tempSellPrice
              .times(tempInput)
              .div(
                one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum))
              )
              .div(tempContractValue)
              .times(tempMultipleNum);
            let tempVal3 = new BigNumber(tempValV2.toNumber());
            // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
            let sellAmountSelf = tempVal3
              .times(tempContractValue)
              .div(tempSellPrice)
              .toString();
            if (this.settlement_precision > 0) {
              this.sellTransactionAmountSelf = sellAmountSelf.substring(
                0,
                sellAmountSelf.indexOf(".") + this.settlement_precision + 1
              );
            } else {
              this.sellTransactionAmountSelf = sellAmountSelf.substring(
                0,
                sellAmountSelf.indexOf(".")
              );
            }
          } else if (value == 0 && !this.inputControl) {
            this.sellNumSelf = "";
            this.sellTransactionAmountSelf = "";
          }
        }
      }
    },
    // 市价交易额变化
    onBuyAmountChange(val) {
      this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
      this.inputControl = true;
      let tempInput = new BigNumber(val);
      let tempAssetsInfoShow = new BigNumber(this.assetsInfoShow);
      let tempVal = tempInput.div(tempAssetsInfoShow).toNumber();
      let tempVal2 = JSON.parse(JSON.stringify(tempVal)) * 100;
      if (tempVal2 > 100) {
        tempVal2 = 100;
      }
      // 买入滑块
      this.buySliderValue = Number(tempVal2.toFixed(0));
      // 买入 预开仓数量
      let tempValV2 = "0";
      let bigMarketPrice = new BigNumber(
        JSON.parse(JSON.stringify(this.marketPrice))
      );
      if (this.buyAmount == "0") {
        tempValV2 = "0";
      } else if (this.buyAmount != "" && this.marketInfoShow.last) {
        let bigBuyAmount = new BigNumber(this.buyAmount);
        let tempMultipleNum = new BigNumber(this.multipleNum);
        let tempContractValue = new BigNumber(this.contract_value);
        let tempFeeRate = new BigNumber(this.fee_rate);
        let tenThousand = new BigNumber(10000);
        let one = new BigNumber(1);
        // let tempOpenPositionRate = new BigNumber(
        //   this.open_position_rate / 10000
        // );
        // 持仓张数 = 开仓价 × 开仓本金 / (1 + 手续费率 ×杠杆倍数)/单张仓位价值 * 杠杆倍数
        // 持仓张数 = 49504 × 1 / (1 + 10 *20)/100 * 20 =

        tempValV2 = bigMarketPrice
          .times(tempInput)
          .div(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum)))
          .div(tempContractValue)
          .times(tempMultipleNum);
        let tempVal3 = new BigNumber(tempValV2.toNumber());
        // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
        let tempVolume = tempVal3
          .times(tempContractValue)
          .div(bigMarketPrice)
          .toString();

        if (this.number_precision > 0) {
          this.tradingVolume = tempVolume.substring(
            0,
            tempVolume.indexOf(".") + this.number_precision + 1 // +1 保留小数点位数
          );
        } else {
          this.tradingVolume = tempVolume.substring(0, tempVolume.indexOf("."));
        }
      } else {
        tempValV2 = "0";
      }

      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    // 市价买入数量变化
    onBuyTotalChange(val) {
      this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
      this.inputControl = true;
      //  开仓本金 = 预计开仓数量 ×  (1 + 手续费率 ×杠杆倍数) / 杠杆倍数

      let tempValV2 = "0";
      if (this.tradingVolume == "0") {
        tempValV2 = "0";
      } else if (this.tradingVolume != "" && this.marketInfoShow.last) {
        let bigBuyAmount = new BigNumber(this.tradingVolume);
        let bigMarketInfoShow = new BigNumber(
          JSON.parse(JSON.stringify(this.marketPrice))
        );
        let tempInput = new BigNumber(val);
        let one = new BigNumber(1);
        let tenThousand = new BigNumber(10000);
        let tempFeeRate = new BigNumber(this.fee_rate);
        let tempMultipleNum = new BigNumber(this.multipleNum);

        tempValV2 = tempInput
          .times(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum)))
          .div(tempMultipleNum);
      } else {
        tempValV2 = "0";
      }
      this.buyAmount = tempValV2.toNumber();

      let tempAssetsInfoShow = new BigNumber(this.assetsInfoShow);
      let tempBuyAmount = new BigNumber(this.buyAmount);
      let tempVal = tempBuyAmount.div(tempAssetsInfoShow).toNumber();
      let tempVal2 = JSON.parse(JSON.stringify(tempVal)) * 100;
      if (tempVal2 > 100) {
        tempVal2 = 100;
      }
      this.buySliderValue = Number(tempVal2.toFixed(0));

      // 买入滑块
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    // 限价买入量变化
    onBuyChangeV2(type) {
      // buyPriceSelf  限价买入价3 buyAmountSelf 现价买入金额4   tradingVolumeSelf// 现价买入数量6
      if (
        this.buyPriceSelf == "" ||
        this.buyPriceSelf == 0 ||
        this.buyAmountSelf == "" ||
        this.buyAmountSelf == 0
      ) {
        this.tradingVolumeSelf = "";
        return;
      }

      if (this.userInfo) {
        this.inputControl = true;
        let tempPrice = new BigNumber(this.buyPriceSelf); // 自定义买入价格
        let tempInput = new BigNumber(this.buyAmountSelf); // 自定义买入数量
        let tempAssetsInfoShow = new BigNumber(this.assetsInfoShow);
        let tempMultipleNum = new BigNumber(this.multipleNum);

        let tempValue = tempInput.div(tempAssetsInfoShow).toNumber() * 100;
        this.buySliderValue = Number(tempValue.toFixed(2));

        if (this.buyPriceSelf != "" && this.buyAmountSelf != "") {
          let tempMultipleNum = new BigNumber(this.multipleNum);
          let tempContractValue = new BigNumber(this.contract_value);
          let tempFeeRate = new BigNumber(this.fee_rate);
          let tenThousand = new BigNumber(10000);
          let one = new BigNumber(1);
          // let tempOpenPositionRate = new BigNumber(
          //   this.open_position_rate / 10000
          // );
          // 持仓张数 = 开仓价 × 开仓本金 / (1 + 手续费率 ×杠杆倍数)/单张仓位价值 * 杠杆倍数
          // 持仓张数 = 49504 × 1 / (1 + 10 *20)/100 * 20 =

          let tempValV2 = tempPrice
            .times(tempInput)
            .div(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum)))
            .div(tempContractValue)
            .times(tempMultipleNum);
          let tempVal3 = new BigNumber(tempValV2.toNumber());
          // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
          let tempVolumeSelf = tempVal3
            .times(tempContractValue)
            .div(tempPrice)
            .toString();

          if (this.settlement_precision > 0) {
            this.tradingVolumeSelf = tempVolumeSelf.substring(
              0,
              tempVolumeSelf.indexOf(".") + this.settlement_precision + 1
            ); // +1 保留小数点位数
          } else {
            this.tradingVolumeSelf = tempVolumeSelf.substring(
              0,
              tempVolumeSelf.indexOf(".")
            );
          }
        }
      }

      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    onBuyChangeV3() {
      // buyPriceSelf  限价买入价3 buyAmountSelf 现价买入金额4   tradingVolumeSelf// 现价买入数量6
      if (this.tradingVolumeSelf == "" || Number(this.tradingVolumeSelf) == 0) {
        this.inputControl = true;
        this.buySliderValue = 0;
        this.buyAmountSelf = "";
        this.tradingVolumeSelf = "";
        return;
      }
      this.inputControl = true;
      if (this.buyPriceSelf != "") {
        let tempPrice = new BigNumber(this.buyPriceSelf); // 自定义买入价格
        let tempAssetsInfoShow = new BigNumber(this.assetsInfoShow); // 资产

        let tempInput = new BigNumber(this.tradingVolumeSelf); // 现价买入数量6
        let one = new BigNumber(1);
        let tenThousand = new BigNumber(10000);
        let tempFeeRate = new BigNumber(this.fee_rate);
        let tempMultipleNum = new BigNumber(this.multipleNum);

        let tempValue = tempInput
          .times(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum)))
          .div(tempMultipleNum);

        let tempValueV2 = tempValue.div(tempAssetsInfoShow).toNumber() * 100;

        this.buySliderValue = Number(tempValueV2.toFixed(2));

        this.buyAmountSelf = tempValue.toFixed(this.price_precision);
      } else {
        this.buySliderValue = 0;
        this.buyAmountSelf = "";
      }
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    // 9
    onSellNumChangeMarket() {
      // sellNum  卖出金额9  sellTransactionAmount  卖出数量10
      if (this.userInfo) {
        if (this.sellNum == "") {
          this.sellSliderValue = 0;
          this.sellTransactionAmount = "";
        }
        this.inputControl = true;
        this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
        let tempMarketPrice = new BigNumber(this.marketPrice);
        let tempSellNum = new BigNumber(this.sellNum);
        let tempAssetsInfoShow = new BigNumber(this.assetsInfoShow);

        let tempMultipleNum = new BigNumber(this.multipleNum);
        let tempContractValue = new BigNumber(this.contract_value);
        let tempFeeRate = new BigNumber(this.fee_rate);
        let tenThousand = new BigNumber(10000);
        let one = new BigNumber(1);
        // let tempOpenPositionRate = new BigNumber(
        //   this.open_position_rate / 10000
        // );
        // 持仓张数 = 开仓价 × 开仓本金 / (1 + 手续费率 ×杠杆倍数)/单张仓位价值 * 杠杆倍数
        // 持仓张数 = 49504 × 1 / (1 + 10 *20)/100 * 20 =

        let tempValV2 = tempMarketPrice
          .times(tempSellNum)
          .div(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum)))
          .div(tempContractValue)
          .times(tempMultipleNum);
        let tempVal3 = new BigNumber(tempValV2.toNumber());
        // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
        let tempSellAmount = tempVal3
          .times(tempContractValue)
          .div(tempMarketPrice)
          .toString();

        if (this.settlement_precision > 0) {
          this.sellTransactionAmount = tempSellAmount.substring(
            0,
            tempSellAmount.indexOf(".") + this.settlement_precision + 1
          );
        } else {
          this.sellTransactionAmount = tempSellAmount.substring(
            0,
            tempSellAmount.indexOf(".")
          );
        }

        let tempValue = tempSellNum.div(tempAssetsInfoShow).toNumber() * 100;

        this.sellSliderValue = Number(tempValue.toFixed(0));
      }

      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    // 8 12
    onSellNumChange() {
      //  sellPrice 卖出价格8 sellNumSelf 卖出金额12  sellTransactionAmountSelf  卖出数量11
      if (
        this.sellPrice == "" ||
        this.sellPrice == 0 ||
        this.sellNumSelf == "" ||
        this.sellNumSelf == 0
      ) {
        this.sellTransactionAmountSelf = "";
        return;
      }

      if (this.userInfo) {
        this.inputControl = true;
        // this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
        let tempMarketPrice = new BigNumber(this.sellPrice);
        let tempSellNumSelf = new BigNumber(this.sellNumSelf);
        let tempAssetsInfoShow = new BigNumber(this.assetsInfoShow);

        let tempMultipleNum = new BigNumber(this.multipleNum);
        let tempContractValue = new BigNumber(this.contract_value);
        let tempFeeRate = new BigNumber(this.fee_rate);
        let tenThousand = new BigNumber(10000);
        let one = new BigNumber(1);
        // let tempOpenPositionRate = new BigNumber(
        //   this.open_position_rate / 10000
        // );
        // 持仓张数 = 开仓价 × 开仓本金 / (1 + 手续费率 ×杠杆倍数)/单张仓位价值 * 杠杆倍数
        // 持仓张数 = 49504 × 1 / (1 + 10 *20)/100 * 20 =

        let tempValV2 = tempMarketPrice
          .times(tempSellNumSelf)
          .div(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum)))
          .div(tempContractValue)
          .times(tempMultipleNum);
        let tempVal3 = new BigNumber(tempValV2.toNumber());
        // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
        let sellAmountSelf = tempVal3
          .times(tempContractValue)
          .div(tempMarketPrice)
          .toString();
        if (this.settlement_precision > 0) {
          this.sellTransactionAmountSelf = sellAmountSelf.substring(
            0,
            sellAmountSelf.indexOf(".") + this.settlement_precision + 1
          );
        } else {
          this.sellTransactionAmountSelf = sellAmountSelf.substring(
            0,
            sellAmountSelf.indexOf(".")
          );
        }

        let tempValue =
          tempSellNumSelf.div(tempAssetsInfoShow).toNumber() * 100;

        this.sellSliderValue = Number(tempValue.toFixed(0));
      }

      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    // 卖出金额变化  卖出数量11
    onSellNumChangeSelf(val) {
      //  sellPrice 卖出价格8 sellNumSelf 卖出金额12  sellTransactionAmountSelf  卖出数量11
      if (this.sellPrice == "") {
        this.sellNumSelf = "";
        return;
      }
      if (this.userInfo) {
        this.inputControl = true;
        this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
        let tempPrice = new BigNumber(this.sellPrice);
        let tempInput = new BigNumber(val);
        let tempAssetsInfoShow = new BigNumber(this.assetsInfoShow);
        let tempMultipleNum = new BigNumber(this.multipleNum);
        let tempFeeRate = new BigNumber(this.fee_rate);
        let one = new BigNumber(1);
        let tenThousand = new BigNumber(10000);

        if (val != "") {
          this.sellNumSelf = tempInput
            .times(
              one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum))
            )
            .div(tempMultipleNum)
            .toFixed(this.price_precision);
          let tempSellNumSelf = new BigNumber(this.sellNumSelf);
          this.sellSliderValue =
            tempSellNumSelf.div(tempAssetsInfoShow).toNumber() * 100;
        } else {
          this.sellNumSelf = "";
          this.sellSliderValue = 0;
        }
        setTimeout(() => {
          this.inputControl = false;
        }, 1000);
      }

      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },

    // 卖出金额变化 10
    onSellTotalChange(val) {
      // sellNum  卖出金额9  sellTransactionAmount  卖出数量10
      if (this.userInfo) {
        this.inputControl = true;
        this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
        let tempMarketPrice = new BigNumber(this.marketPrice);
        let tempInput = new BigNumber(val);
        let tempMultipleNum = new BigNumber(this.multipleNum);
        let one = new BigNumber(1);
        let tenThousand = new BigNumber(10000);

        let tempAssetsInfoShow = new BigNumber(this.assetsInfoShow);

        if (val != "") {
          this.sellNum = tempInput
            .times(
              one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum))
            )
            .div(tempMultipleNum)
            .toFixed(this.settlement_precision);

          let tempSellNum = new BigNumber(this.sellNum);
          this.sellSliderValue =
            tempSellNum.div(tempAssetsInfoShow).toNumber() * 100;
        } else {
          this.sellNum = "";
          this.sellSliderValue = 0;
        }
        setTimeout(() => {
          this.inputControl = false;
        }, 1000);
      }
    },
    formatTooltip(val) {
      return val + "%";
    },
    onTrade(type) {
      // 1买入 2卖出
      if (type == 1) {
        if (this.currChainValue == "1") {
          if (this.buyAmount == "" || Number(this.buyAmount == 0)) {
            showMessage({
              message: this.translateTitle("请输入交易额"),
              type: "error",
            });
            return;
          }
          if (Number(this.buyAmount) > Number(this.assetsInfoShow)) {
            showMessage({
              message: this.translateTitle("可用余额不足"),
              type: "error",
            });
            return;
          }
          this.buyBtnLoading = true;
          let params = {
            leverage: this.multipleNum,
            symbol_id: this.swap_symbol_id,
            open_side: "1", //开仓方向 1 买涨 2 买跌
            consumer_costs: this.buyAmount, // 购买数量
            market_price: this.marketInfoShow.last, // 购买数量
          };
          apiOpenPosition(params).then(res => {
            if (res.status == 200) {
              showMessage({
                message: this.translateTitle("下单成功"),
                type: "success",
              });
              this.buyBtnLoading = false;
              this.reSetData();
              this.$emit("on-trade-success", "1");
            } else {
              this.buyBtnLoading = false;
            }
          });
        } else if (this.currChainValue == "2") {
          if (this.buyPriceSelf == "" || Number(this.buyPriceSelf == 0)) {
            showMessage({
              message: this.translateTitle("请输入买入价格"),
              type: "error",
            });
            return;
          }
          if (this.buyAmountSelf == "" || Number(this.buyAmountSelf == 0)) {
            showMessage({
              message: this.translateTitle("请输入买入量"),
              type: "error",
            });
            return;
          }

          if (Number(this.buyAmountSelf) > Number(this.assetsInfoShow)) {
            showMessage({
              message: this.translateTitle("可用余额不足"),
              type: "error",
            });
            return;
          }
          this.buyBtnLoading = true;
          let params = {
            leverage: this.multipleNum,
            symbol_id: this.swap_symbol_id,
            open_side: "1", //开仓方向 1 买涨 2 买跌
            consumer_costs: this.buyAmountSelf, // 购买数量
            trigger_price: this.buyPriceSelf,
          };
          apiPlanOrder(params).then(res => {
            if (res.status == 200) {
              showMessage({
                message: this.translateTitle("下单成功"),
                type: "success",
              });
              this.buyBtnLoading = false;
              this.reSetData();
              this.$emit("on-trade-success", "2");
            } else {
              this.buyBtnLoading = false;
            }
          });
        }
      } else {
        // 2 卖出
        if (this.currChainValue == "1") {
          if (this.sellNum == "" || Number(this.sellNum == 0)) {
            showMessage({
              message: this.translateTitle("请输入卖出量"),
              type: "error",
            });
            return;
          }

          if (Number(this.sellNum) > Number(this.assetsInfoShow)) {
            showMessage({
              message: this.translateTitle("可用余额不足"),
              type: "error",
            });
            return;
          }
          this.sellBtnLoading = true;
          let params = {
            leverage: this.multipleNum,
            symbol_id: this.swap_symbol_id,
            open_side: "2", //开仓方向 1 买涨 2 买跌
            bail_number: this.sellNum, // 购买数量
            consumer_costs: this.sellNum,
            market_price: this.marketInfoShow.last, // 购买数量
          };
          apiOpenPosition(params).then(res => {
            if (res.status == 200) {
              showMessage({
                message: this.translateTitle("下单成功"),
                type: "success",
              });
              this.sellBtnLoading = false;
              this.reSetData();
              this.$emit("on-trade-success", "1");
            } else {
              this.sellBtnLoading = false;
            }
          });
        } else if (this.currChainValue == "2") {
          if (this.sellPrice == "" || Number(this.sellPrice == 0)) {
            showMessage({
              message: this.translateTitle("请输入卖出价"),
              type: "error",
            });
            return;
          }
          if (this.sellNumSelf == "" || Number(this.sellNumSelf == 0)) {
            showMessage({
              message: this.translateTitle("请输入卖出量"),
              type: "error",
            });
            return;
          }

          if (Number(this.sellNumSelf) > Number(this.assetsInfoShow)) {
            showMessage({
              message: this.translateTitle("可用余额不足"),
              type: "error",
            });
            return;
          }
          this.sellBtnLoading = true;
          let params = {
            leverage: this.multipleNum,
            symbol_id: this.swap_symbol_id,
            open_side: "2", //开仓方向 1 买涨 2 买跌
            bail_number: this.sellNumSelf, // 购买数量
            consumer_costs: this.sellNumSelf,
            trigger_price: this.sellPrice,
          };
          apiPlanOrder(params).then(res => {
            if (res.status == 200) {
              showMessage({
                message: this.translateTitle("下单成功"),
                type: "success",
              });
              this.sellBtnLoading = false;
              this.reSetData();
              this.$emit("on-trade-success", "2");
            } else {
              this.sellBtnLoading = false;
            }
          });
        }
      }
    },
    goLogin(type) {
      if (type == 1) {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: { redirect: this.$route.fullPath },
        });
      } else {
        this.$router.push(this.routeSplicing(this.language, "register"));
      }
    },
    onChainChange(value) {
      this.inputControl = true;
      this.currChainValue = value;
      this.sellSliderValue = 0;
      this.buySliderValue = 0;
      this.buyNum = "";
      this.buyPriceSelf = "";
      this.buyPrice = "";
      this.buyAmount = "";
      this.sellTransactionAmountSelf = "";
      this.sellNumSelf = "";
      this.sellPrice = "";
      this.sellNum = "";
      this.tradingVolume = "";
      this.tradingVolumeSelf = "";
      this.transactionAmount = "";
      this.sellTransactionAmount = "";
      this.buyAmountSelf = "";
      setTimeout(() => {
        this.inputControl = false;
      }, 300);
    },
    reSetData() {
      this.inputControl = true;
      this.sellSliderValue = 0;
      this.buySliderValue = 0;
      this.buyNum = "";
      this.sellTransactionAmountSelf = "";
      this.buyPriceSelf = "";
      this.buyPrice = "";
      this.buyAmount = "";
      this.sellNumSelf = "";
      this.sellPrice = "";
      this.sellNum = "";
      this.tradingVolume = "";
      this.tradingVolumeSelf = "";
      this.transactionAmount = "";
      this.sellTransactionAmount = "";
      this.buyAmountSelf = "";
      setTimeout(() => {
        this.inputControl = false;
      }, 300);
    },
    async getStaticTableInfo(sv_id) {
      const { status, data } = await apiGetStaticTableInfo(sv_id);
      if (status == 200) {
        this.coinInfoMap = JSON.parse(JSON.stringify(data));
        if (this.swap_symbol_id) {
          this.number_precision = this.coinInfoMap[
            this.swap_symbol_id
          ].number_precision;
          this.open_position_rate = this.coinInfoMap[
            this.swap_symbol_id
          ].open_position_rate;
          this.price_precision = this.coinInfoMap[
            this.swap_symbol_id
          ].price_precision;
          this.contract_value = this.coinInfoMap[
            this.swap_symbol_id
          ].contract_value;
          this.fee_rate = this.coinInfoMap[this.swap_symbol_id].fee_rate;
          this.settlement_precision = this.coinInfoMap[
            this.swap_symbol_id
          ].settlement_precision;
        }
      }
    },
  },
};
</script>
<style lang="scss">
.currency-based-contract-component-trade {
  // z-index: 2001;
  background-color: #ffffff;
  margin-top: 8px;
  user-select: none;
  // height: 340px;
  position: relative;
  .trade-header {
    height: 32px;
    line-height: 32px;
    padding-left: 15px;
    background-color: #f5f5f7;
    font-size: 14px;
    font-weight: 600;
    color: #1f2533;
    .multiple-num {
      cursor: pointer;
      width: 68px;
      height: 18px;
      margin: 7px 0 1px 5px;
      padding: 0px 8px 0 8px;
      border-radius: 2px;
      background-color: #3348f6;
      font-size: 12px;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
      line-height: 18px;
      img {
        vertical-align: 10%;
        width: 6px;
        height: 4px;
      }
    }
  }
  .trade-content {
    display: flex;
    justify-content: space-evenly;
    height: 300px;
    .left-item,
    .right-item {
      width: 50%;
      // margin: 10px 15px;
      .item-header {
        font-size: 12px;
        color: #bec1c8;
        height: 26px;
        &.V2 {
          height: 8px;
        }
        &.V3 {
          height: 26px;
        }
      }
    }
    .left-item {
      padding: 10px 15px;
      border-right: 1px solid #f1f2f5;
      .el-slider__bar {
        background-color: #03ad79;
      }
      .el-slider__button {
        border: 2px solid #03ad79;
      }
      .el-slider {
        .disabled {
          .el-slider__button {
            border-color: #c0c4cc;
          }
        }
      }
    }
    .right-item {
      padding: 10px 15px;
      .el-slider__bar {
        background-color: #c61b3d;
      }
      .el-slider__button {
        border: 2px solid #c61b3d;
      }
      .el-slider {
        .disabled {
          .el-slider__button {
            border-color: #c0c4cc;
          }
        }
      }
    }
    .trade-item {
      position: relative;
      height: 250px;
      .buy-num-content {
        margin-top: 20px;
        .buy-num-text {
          color: #9aa1b1;
          font-size: 12px;
          font-weight: 500;
        }
        .buy-num {
          display: inline;
          color: #354052;
          font-size: 12px;
          font-weight: 500;
          text-align: right;
          float: right;
        }
      }
      .trade-type {
        display: flex;
        justify-content: flex-start;
        .item-tabs {
          box-sizing: border-box;
          font-size: 14px;
          display: inline-block;
          margin-right: 10px;
          width: 31%;
          height: 28px;
          border-radius: 2px;
          color: #354052;
          line-height: 28px;
          text-align: center;
          border: solid 1px #dfdfdf;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          &.active {
            color: #3348f6;
            // background-color: rgba(39, 198, 131, 0.1);
            border: solid 1px #3348f6;
          }
        }
      }

      .buy-price,
      .sell-price {
        margin-top: 8px;

        .el-input__inner::-moz-placeholder {
          color: #1f2533 !important;
          font-size: 14px;
          font-weight: 400 !important;
        }

        .el-input__inner::placeholder {
          color: #1f2533 !important;
          font-size: 14px;
          font-weight: 400 !important;
        }
        .el-input__inner::-webkit-input-placeholder {
          color: #1f2533 !important;
          font-size: 14px;
          font-weight: 400 !important;
        }

        .el-input__inner {
          height: 32px;
          line-height: 32px;
          background: #f1f2f5;
          padding-left: 50px;
          padding-right: 60px;
          text-align: right;
          color: #354052;
          border-radius: 2px;
          border: none;
          &:hover {
            border: none !important;
            background: #ebeced !important;
          }
          &:active {
            border: solid 1px #3348f6 !important;
            background-color: #f5f5f6 !important;
          }
          &:focus {
            border: solid 1px #3348f6 !important;
            background-color: #f5f5f6 !important;
          }
        }
        .el-input__prefix,
        .el-input__suffix {
          line-height: 32px;
        }
      }
      .market-price {
        .el-input__inner {
          &:hover {
            border: none !important;
            background: #f1f2f5 !important;
          }
        }
      }
      .block {
        height: 50px;
        .el-slider {
          height: 28px;
          padding: 0 5px;
        }
      }
      .trade-amount {
        font-size: 12px;
        color: #7f8490;
        height: 26px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .b-color {
          color: #354052;
          font-weight: 500;
        }
      }
      .trade-btn {
        // position: absolute;
        // bottom: 10px;
        position: absolute;
        width: 100%;
        bottom: 0px;
        margin-top: 10px;
        .btn {
          cursor: pointer;
          vertical-align: middle;
          text-align: center;
          font-size: 14px;
          display: inline-block;
          width: 130px;
          line-height: 1;
          color: white;
          box-sizing: border-box;
          width: 100%;
          height: 36px;
          border-radius: 2px;
          padding: 0;
          border: none;
          &.trade-buy {
            background-color: #03ad79;
          }
          &.trade-sell {
            background-color: #c61b3d;
          }
          &.login {
            line-height: 36px;
            cursor: auto;
            background-color: #fff;
            border: 1px solid #e2e2e4;
            .or-text {
              color: #7f8490;
            }
            .common-operate {
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
</style>
