<template>
  <div class="component-history-order">
    <div class="market-header">
      <ul>
        <li
          class="classify-area"
          :class="{ active: currIndex == item.value }"
          v-for="(item, index) of tabList"
          :key="item.value"
          @click="onAreaChange(item)"
        >
          {{ translateTitle(item.label) }}
          <span v-if="index == 0 && orderNum > 0">( {{ orderNum }} )</span>
          <i v-if="currIndex == item.value" class="item-bar"></i>
        </li>
        <li class="hidden-btn">
          <el-checkbox v-if="currIndex == 2" v-model="hidenCancelChecked">{{
            translateTitle("隐藏已撤销")
          }}</el-checkbox>
          <el-checkbox v-model="checked">{{
            translateTitle("隐藏其他交易对")
          }}</el-checkbox>
          <!-- 隐藏其他交易对 -->
        </li>
      </ul>
    </div>
    <div
      class="table-content"
      style="min-height:290px"
      v-loading="orderLoading"
    >
      <!-- <el-table
        :data="tableData"
        :height="currIndex == 1 ? 350 : 290"
        style="width: 100%"
      > -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          v-if="currIndex == 1"
          prop="create_time"
          align="center"
          :label="translateTitle('下单时间')"
          key="1"
          min-width="170"
        >
          <template slot-scope="{ row }">
            <span>{{ parseTime(row.create_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="currIndex == 2"
          prop="create_time"
          align="center"
          :label="translateTitle('下单时间')"
          key="10"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <span>{{ parseTime(row.create_time) }}</span>
          </template>
        </el-table-column>

        <el-table-column
          key="2"
          align="center"
          prop="symbol_name"
          :label="translateTitle('交易对')"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <span @click="onSymbolClick(row)" class="common-operate">{{
              row.symbol_name
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="side"
          key="3"
          :label="translateTitle('方向')"
          min-width="90"
        >
          <template slot-scope="{ row }">
            <span :class="'side_' + row.side">{{ sideMap[row.side] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="currIndex != 3"
          align="right"
          prop="price"
          key="4"
          :label="translateTitle('委托价格')"
          min-width="90"
        >
          <!-- :label="`委托价(${to_symbol.toUpperCase()})`" -->
          <template slot-scope="{ row }">
            <div v-if="row.type == 1">
              <span class="DINPro">{{ row.price }}</span>
            </div>
            <div v-else>
              <span>{{ translateTitle("市价") }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="currIndex != 1"
          key="5"
          align="right"
          prop="deal_avg_price"
          :label="translateTitle('成交均价')"
          min-width="130"
        >
          <!-- :label="`成交均价(${to_symbol.toUpperCase()})`" -->
          <template slot-scope="{ row }">
            <span class="DINPro" v-if="row.status == 3">--</span>
            <span class="DINPro" v-else>{{ row.deal_avg_price }}</span>
          </template>
        </el-table-column>
        <el-table-column
          key="6"
          align="right"
          prop="deal_num"
          :label="translateTitle('委托数量')"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <span>
              <span v-if="currIndex == 1" class="DINPro"
                >{{ formatNumberRgx(row.num) }} {{ row.coin_name }}</span
              >
              <!-- {{ row.coin_name }} -->
              <span v-else-if="currIndex == 2" class="DINPro"
                >{{ formatNumberRgx(row.deal_num) }} {{ row.coin_name }}
              </span>
            </span>
            <!-- {{ row.coin_name }} -->
          </template>
        </el-table-column>
        <el-table-column
          key="7"
          prop="total"
          align="right"
          min-width="130"
          :label="translateTitle('委托总额')"
        >
          <!-- :label="`委托总额(${to_symbol.toUpperCase()})`" -->
          <template slot-scope="{ row }">
            <span class="DINPro"
              >{{ formatNumberRgx(row.total) }} {{ row.coin_to_name }}</span
            >
          </template>
        </el-table-column>
        <!-- <el-table-column prop="address" label="已成交" min-width="130">
      </el-table-column>
      <el-table-column prop="address" label="未成交" min-width="130">
      </el-table-column> -->
        <el-table-column
          v-if="currIndex != 1"
          align="right"
          key="9"
          prop="fee"
          :label="translateTitle('手续费')"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <span class="DINPro" v-if="row.status == 3">--</span>
            <span class="DINPro" v-else
              >{{ formatNumberRgx(row.fee) }}

              <span v-if="row.side == 'buy'">
                {{ row.coin_name }}
              </span>
              <span v-else>{{ row.coin_to_name }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="currIndex != 1"
          align="center"
          key="8"
          prop="status"
          :label="translateTitle('状态')"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <span :class="'status_' + row.status">{{
              translateTitle(statusMap[row.status])
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="currIndex == 2"
          prop="update_time"
          align="center"
          :label="translateTitle('完成时间')"
          key="11"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <span>{{ parseTime(row.update_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="currIndex == 1"
          :label="translateTitle('操作')"
          key="9"
          align="center"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <el-button
              @click="onOpenConfirm(row.trust_id)"
              class="cancel-btn"
              >{{ translateTitle("撤销") }}</el-button
            >
          </template>
        </el-table-column>
        <no-data v-show="!orderLoading" slot="empty"></no-data>
      </el-table>
      <div
        class="common-table-pages"
        v-if="tableData.length > 0 && currIndex != 1"
      >
        <p>
          {{ translateTitle("共") }}
          <span>{{ pageInfo.pageCount }}</span>
          {{ translateTitle("页") }}
        </p>
        <el-pagination
          class="mt20"
          background
          layout="prev, pager, next"
          :total="pageInfo.pageTotal"
          :page-size="pageInfo.pageSize"
          :current-page.sync="pageInfo.pageCurrent"
          @current-change="onCurrentPageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- <el-pagination
      v-if="tableData.length > 0 && currIndex != 1"
      :current-page.sync="pageInfo.pageCurrent"
      :layout="layout"
      :page-size="pageInfo.pageSize"
      :total="pageInfo.pageTotal"
      background
      @current-change="onCurrentPageChange"
    /> -->
    <el-dialog
      :title="translateTitle('操作提示')"
      custom-class="confirm-dialog"
      :visible.sync="dialogVisible"
      :lock-scroll="false"
      width="440px"
    >
      <span style="font-size: 16px">{{
        translateTitle("确认撤销此订单？")
      }}</span>
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="dialogVisible = false">{{
          translateTitle("取消")
        }}</span>
        <span class="dialog-confirm-btn" @click="onOK">{{
          translateTitle("确认")
        }}</span>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import {
  apiGetOrderList,
  apiTrustCancel,
  apiTrustIndex,
  apiTrustDealIndex,
} from "@/model/exchange/order.js";
import NoData from "@/components/common/NoData";
import { parseTime } from "@/utils/index.js";
import { mapActions, mapGetters } from "vuex";
import { showMessage } from "@/components/dialog/message.js";
import * as Account from "@/model/user/account";
import { apiGetStaticTableInfo } from "@/model/settings";
export default {
  props: {
    marketList: {
      type: Array,
      default: function() {
        return [];
      },
    },
    exchangeCoinMap: {
      type: Object,
      default: function() {
        return {};
      },
    },
    swap_symbol_id: {
      type: [String, Number],
      default: function() {
        return 22;
      },
    },
    from_symbol: {
      type: [String, Number],
    },
    to_symbol: {
      type: [String, Number],
    },
  },
  mixins: [commonMixin],
  components: {
    NoData,
  },
  data() {
    return {
      parseTime,
      ws: null,
      tableData: [],
      tableDataComputed: [],
      orderLoading: true,
      dialogVisible: false,
      hidenCancelChecked: false,
      checked: false,
      layout: "total, prev, pager, next",
      symbolId: "",
      trust_id: "",
      orderNum: 0,
      pageInfo: {
        pageCount: 1,
        pageCurrent: 1,
        pageSize: 6,
        pageTotal: 6,
      },
      tabList: [
        {
          value: "1",
          label: "当前委托",
        },
        {
          value: "2",
          label: "历史委托",
        },
        // {
        //   value: "3",
        //   label: "成交明细",
        // },
      ],
      currIndex: "1",
      coinItemIdMap: {},
      tradeMap: {
        1: this.translateTitle("市价"),
        2: this.translateTitle("限价"),
      },
      sideMap: {
        buy: this.translateTitle("买入"),
        sell: this.translateTitle("卖出"),
      },
      statusMap: {
        0: this.translateTitle("未成交"),
        1: this.translateTitle("部分成交"),
        2: this.translateTitle("完全成交"),
        3: this.translateTitle("已撤销"),
      },
    };
  },

  async created() {
    await this.getExchangeItemId();
    let { userInfo } = await Account.getUserInfo();
    if (userInfo) {
      this.startTimer();
    }
  },
  mounted() {},
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
    }),
    tableHeight() {
      let tempHeight = 300;
      tempHeight = this.currIndex == 1 ? 360 : 300;
      return tempHeight;
    },
  },
  methods: {
    onOK() {
      this.onCancelOrder();
      this.dialogVisible = false;
    },
    onOpenConfirm(id) {
      this.trust_id = id;
      this.dialogVisible = true;
    },
    async getExchangeItemId() {
      const { status, data } = await apiGetStaticTableInfo(13);
      if (status == 200) {
        this.coinItemIdMap = JSON.parse(JSON.stringify(data));
      }
    },
    reset(type) {
      this.orderLoading = true;
      this.currIndex = type.toString();
      this.pageInfo.pageCurrent = 1;
      this.initData(this.checked);
    },
    onSymbolChange(item) {
      this.$emit("on-symbol-change", item);
    },
    onSymbolClick(row) {
      let params = {
        symbol_id: row.symbol_id,
        info: {
          coin_from: row.coin_from,
          coin_to: row.coin_to,
          name: row.symbol_name_v2,
        },
      };
      this.$emit("on-symbol-change", params);
    },
    async startTimer() {
      const timer = setTimeout(() => {
        this.initData(this.checked);
        this.startTimer();
      }, 3000);
      this.$once("hook:beforeDestroy", () => {
        clearTimeout(timer);
      });
    },
    async onCancelOrder() {
      const req = {
        trust_id: this.trust_id,
      };
      const { status, data } = await apiTrustCancel(req);
      if (status == 200) {
        showMessage({
          message: this.translateTitle("撤销成功"),
          type: "success",
        });
        this.initData(this.checked);
      }
    },
    onAreaChange(item) {
      this.orderLoading = true;
      this.tableData = [];
      this.currIndex = item.value;
      this.pageInfo.pageCurrent = 1;
      this.initData(this.checked);
    },
    async initData(checked = false) {
      if (!this.swap_symbol_id) {
        this.orderLoading = false;
        return;
      }
      let { userInfo } = await Account.getUserInfo();
      if (!userInfo) {
        this.orderLoading = false;
        return;
      }
      let tempStatus = this.currIndex;
      if (this.currIndex == 3) {
        tempStatus = "";
      }
      let temp_symbol_id = this.swap_symbol_id;
      if (checked) {
        temp_symbol_id = this.swap_symbol_id;
      } else {
        temp_symbol_id = "";
      }

      if (this.currIndex == 1) {
        const req = {
          symbol_id: temp_symbol_id,
        };

        return apiTrustIndex(req).then(res => {
          if (res.status == 200 && res.data.length > 0) {
            this.orderNum = res.data.length;
            for (const key in this.exchangeCoinMap) {
              res.data.forEach(ele => {
                if (ele.symbol_id == key) {
                  ele.symbol_name_v2 = this.exchangeCoinMap[
                    key
                  ].name.toUpperCase();
                }
              });
            }
            for (const key in this.coinItemIdMap) {
              res.data.forEach(ele => {
                if (ele.coin_from == key) {
                  ele.coin_name = this.coinItemIdMap[
                    key
                  ].item_name.toUpperCase();
                }
                if (ele.coin_to == key) {
                  ele.coin_to_name = this.coinItemIdMap[
                    key
                  ].item_name.toUpperCase();
                }
              });
            }
            this.tableData = res.data;
          } else {
            this.tableData = [];
            this.orderNum = 0;
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      } else if (this.currIndex == 2) {
        let temp_order_status = "1,2,3";
        if (this.hidenCancelChecked) {
          temp_order_status = "1,2";
        } else {
          temp_order_status = "1,2,3";
        }
        const req = {
          symbol_id: temp_symbol_id,
          status: temp_order_status,
          page: this.pageInfo.pageCurrent,
          page_size: this.pageInfo.pageSize,
        };
        return apiTrustDealIndex(req).then(res => {
          if (res.status == 200) {
            for (const key in this.exchangeCoinMap) {
              res.data.list.forEach(ele => {
                if (ele.symbol_id == key) {
                  ele.symbol_name_v2 = this.exchangeCoinMap[
                    key
                  ].name.toUpperCase();
                }
              });
            }
            for (const key in this.coinItemIdMap) {
              res.data.list.forEach(ele => {
                if (ele.fee_item_id == key) {
                  ele.fee_item_name = this.coinItemIdMap[
                    key
                  ].item_name.toUpperCase();
                }
                if (ele.coin_from == key) {
                  ele.coin_name = this.coinItemIdMap[
                    key
                  ].item_name.toUpperCase();
                }
                if (ele.coin_to == key) {
                  ele.coin_to_name = this.coinItemIdMap[
                    key
                  ].item_name.toUpperCase();
                }
              });
            }
            this.tableData = res.data.list;
            this.pageInfo = res.data.pageInfo;
          } else {
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      }
    },
    onCurrentPageChange() {
      this.orderLoading = true;
      this.initData(this.checked);
    },
  },
  watch: {
    language() {
      this.tradeMap = {
        1: this.translateTitle("市价"),
        2: this.translateTitle("限价"),
      };
      this.sideMap = {
        buy: this.translateTitle("买入"),
        sell: this.translateTitle("卖出"),
      };
      this.statusMap = {
        0: this.translateTitle("未成交"),
        1: this.translateTitle("待成交"),
        2: this.translateTitle("完全成交"),
        3: this.translateTitle("已撤销"),
      };
    },
    swap_symbol_id: {
      handler(newVal, oldVal) {
        if (oldVal == undefined) {
          return;
        }
        if (newVal != oldVal) {
          this.symbolId = newVal;
          this.orderLoading = true;
          this.pageInfo.pageCurrent = 1;
          this.initData(this.checked);
        }
      },
    },
    hidenCancelChecked() {
      this.initData(this.checked);
    },
    checked() {
      this.initData(this.checked);
    },
  },

  computed: {
    // showList() {
    //   return this.marketList;
    // },
  },
};
</script>
<style lang="scss">
.en,
.ja {
  .component-history-order .market-header .classify-area {
    width: 160px;
  }
}
</style>
<style lang="scss">
.component-history-order {
  margin: 10px 0px;
  // height: 385px;
  border-radius: 2px;
  background-color: #ffffff;
  min-width: 1760px;
  overflow-x: auto;
  // padding: 0px 0px 0px 15px;
  position: relative;
  .el-table__empty-text {
    user-select: none;
  }
  .el-table {
    min-height: 290px;
    overflow-y: auto;
    td,
    th {
      padding: 8px 0;
    }
  }
  .market-header {
    // padding-left: 15px;
    background-color: #f5f5f7;
    ul {
      display: flex;
    }
    .classify-area {
      user-select: none;
      padding: 0 10px;
      cursor: pointer;
      font-size: 14px;
      height: 32px;
      width: 110px;
      text-align: center;
      line-height: 32px;
      font-weight: 500;
      color: #7f8490;
      position: relative;
      &.active {
        background-color: #ffffff;
        color: black;
      }
      .item-bar {
        position: absolute;
        width: 42px;
        height: 2px;
        background-color: #1f2533;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
      }
    }
    .hidden-btn {
      margin: 0 15px 0 auto;
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      color: #354052;
      .el-checkbox__label {
        font-size: 12px;
        font-weight: normal;
      }
    }
  }

  .market-search {
    margin-top: 17px;
    input {
      background-color: #f1f2f5;
      border: none;
      outline: none;
      height: 24px;
      border-radius: 24px;
      width: 272px;
    }
  }
  .market-title {
    display: flex;
  }
  .market-content {
    .area-title {
      li {
        display: flex;
        .pair-name-title {
          width: 200px;
        }
        .pair-price-title {
          width: 200px;
          text-align: right;
        }
        .pair-updown-title {
          width: 200px;
          text-align: right;
        }
      }
    }
    .depth-sub-title {
      padding: 0 13px 0 16px;
      display: flex;
      color: #7f8490;
      font-size: 12px;
      font-weight: 500;
      height: 36px;
      line-height: 36px;
      .time {
        width: 108px;
      }
      .price {
        width: 108px;
      }
      .num {
        width: 108px;
        text-align: right;
      }
    }
    .depth-sub-content {
      padding: 0 13px 4px 16px;
      min-height: 500px;
      li {
        display: flex;
        height: 26px;
        line-height: 26px;
        font-size: 12px;
        .time {
          width: 108px;
          color: #1f2533;
        }
        .price {
          width: 108px;
        }
        .num {
          width: 108px;
          color: #080c34;
          text-align: right;
          &.red {
            color: #fa4d56;
          }
          &.green {
            color: #03ad79;
          }
        }
      }
    }
  }
  .pair-wrap {
    .pair-list {
      display: flex;
      height: 26px;
      line-height: 26px;
      .pair-name {
        width: 200px;
      }
      .pair-price {
        width: 200px;
        text-align: right;
      }
      .pair-updown {
        width: 200px;
        text-align: right;
      }
    }
  }
  .side_buy {
    color: #03ad79;
  }
  .side_sell {
    color: #c61b3d;
  }
  .status_1 {
    color: #f78700;
  }
  .status_2 {
    color: #03ad79;
  }
  .status_3 {
    color: #bec1c8;
  }
  .cancel-btn {
    cursor: pointer;
    text-align: center;
    // width: 52px;
    height: 24px;
    padding: 5px 14px 5px;
    // line-height: 24px;
    border-radius: 2px;
    border: solid 1px #e2e2e4;
    background-color: #ffffff;
    color: #3348f6;
    font-size: 12px;
    font-weight: normal;
    outline: none;
    &:hover {
      background-color: #ffffff;
      color: #3348f6;
      border: solid 1px #e2e2e4;
      outline: none;
    }
    &:focus {
      background-color: #ffffff;
      color: #3348f6;
      border: solid 1px #e2e2e4;
      outline: none;
    }
  }
  // .el-pagination {
  //   position: absolute;
  //   bottom: 10px;
  //   right: 20px;
  //   display: flex;
  //   justify-content: flex-end;
  //   height: 60px;
  //   align-items: center;
  // }
  .confirm-dialog {
    // height: 200px;
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .el-dialog__body {
      text-align: center;
      color: #354052;
      padding: 20px 20px 30px;
      font-size: 16px;
      font-weight: normal;
    }
  }
}
</style>
