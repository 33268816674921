<template>
  <div class="charge-fragment-content">
    <template v-if="data.itemDetail">
      <!-- <p class="coin-name">
        {{ data.itemDetail.capital_name + " - " + data.itemDetail.display }}
      </p> -->
      <p class="coin-name">
        {{ data.itemDetail.capital_name }}
      </p>
      <p class="explain-item">
        {{ translateTitle("该地址为") }} {{ data.itemDetail.capital_name }}
        {{ translateTitle("充值地址，充值任何非")
        }}{{ data.itemDetail.capital_name
        }}{{ translateTitle("相关的资产将可能导致资产丢失，无法找回") }}
      </p>

      <div class="operate-item">
        <!-- <el-checkbox class="operate-checkbox" v-model="checked">
          {{ translateTitle("我已了解，确认要充值") }}
          {{ data.itemDetail.capital_name }}</el-checkbox
        > -->
        <!-- <div
          v-if="isRecharge"
          class="common-save look-address"
          @click="getAddress(chain_tag)"
        > -->
        <div
          v-if="isRecharge"
          class="common-save look-address"
          @click="getAddress()"
        >
          {{ translateTitle("查看地址") }}
        </div>
        <div class="maintenance-ing" v-else>
          <el-tooltip popper-class="atooltip" placement="top">
            <div class="title" slot="content">
              {{ translateTitle("充值维护中") }}
            </div>
            <div class="maintenance">{{ translateTitle("确认提币") }}</div>
          </el-tooltip>
        </div>
      </div>

      <el-dialog
        class="c-dialog create-addtess"
        width="820px"
        height="585px"
        :visible.sync="dialogVisible"
        @close="mainClose"
        :show-close="false"
        :close-on-click-modal="false"
      >
        <div slot="title" class="create-address-title">
          <div>
            <img :src="data.itemDetail.coin_url" alt="" />
            <span class="title-data">
              {{ data.itemDetail.capital_name }}
              <span class="sub-title-data"
                >({{ translateTitle("此地址只可接收") }}
                {{ data.itemDetail.capital_name }})</span
              >
            </span>
          </div>
          <div>
            <i
              class="el-dialog__close el-icon el-icon-close"
              @click="mainClose"
            ></i>
          </div>
        </div>
        <div class="header">
          <!-- 选项卡 -->
          <div class="nav">
            <div class="list-type-tab">
              <div
                v-for="item in coinInfoComputed"
                :key="item.value"
                class="tab"
                :class="{ active: item.value == active }"
                @click="onTypeChange(item.value)"
              >
                <span>{{ item.label }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="address-content">
          <div
            :class="addressList.length > 0 ? '' : 'no-data-left'"
            class="left"
          >
            <div class="scrollbar-default">
              <!--:class="getScrollClass"-->
              <div
                v-if="addressList.length"
                class="scroll-bar"
                :class="getScrollClass"
                @scroll="scrollAdress"
              >
                <ul class="coin-info-content">
                  <i class="coin-info-line"></i>
                  <li
                    v-for="(item, index) in addressList"
                    :key="index"
                    :class="{ active: item.ua_id == addressActive }"
                    @click="onAddressChange(item.ua_id)"
                  >
                    <div class="top">
                      <div class="img-content">
                        <!-- <img
                          :src="data.itemDetail.coin_url"
                          :alt="data.itemDetail.capital_name + '.png'"
                        /> -->
                        <span>{{ translateTitle(item.bak) }}:</span>
                      </div>

                      <!-- <span>已充币次数 {{ item.transactions }} 次</span>
                      <span>{{ item.bak }}:</span> -->
                      <span>
                        {{ translateTitle("已充币次数") }}
                        {{ item.transactions }} {{ translateTitle("次") }}</span
                      >
                    </div>
                    <div class="bottom">
                      <div>{{ item.address }}</div>
                    </div>
                  </li>
                  <div class="coin-info-gap"></div>
                </ul>
              </div>
            </div>
            <no-data v-show="!addressList.length" slot="empty"></no-data>

            <div class="crate-address">
              <span class="create-btn" @click="onCreate">
                <!-- <img src="../../../../assets/img/add.png"> -->
                <i class="iconfont icontianjia"></i>
                {{ translateTitle("生成新充币地址") }}
              </span>
              <!-- <span class="add-icon">+</span> -->
            </div>
          </div>

          <div
            :class="addressList.length > 0 ? '' : 'no-data-right'"
            class="right"
          >
            <div v-if="addressList.length">
              <div class="top-title">
                <!-- <img
                  :src="data.itemDetail.coin_url"
                  :alt="data.itemDetail.capital_name + '.png'"
                  class="currentImg"
                /> -->
                <p class="name">
                  {{ data.itemDetail.capital_name }}{{ addressData.bak }}
                </p>
              </div>
              <qrcode-vue
                class="qr-code"
                :value="addressData.address"
                :size="100"
                level="H"
              ></qrcode-vue>
              <!-- <img
                :src="addressData.qrcode_url"
                alt="充币地址.png"
                class="qr-code"
              /> -->

              <div class="address">
                <span> {{ addressData.address }}</span>

                <i
                  class="iconfont iconicon-copy"
                  style="color: #9aa1b1"
                  v-clipboard:copy="addressData.address"
                  v-clipboard:success="copySuccess"
                  v-clipboard:error="copyError"
                ></i>
              </div>
              <!-- <div class="collection">
                <span class="collection-title">{{
                  translateTitle("已充币次数:")
                }}</span>
                <span class="collection-num">{{
                  addressData.transactions
                }}</span>
                <span class="collection-title" style="margin-left: 10px">
                  {{ translateTitle("次") }}
                </span>
              </div> -->
              <div class="buttons">
                <span @click="onModify" class="common-edit">{{
                  translateTitle("修改")
                }}</span>
                <span @click="onDelete" class="common-delete">{{
                  translateTitle("删除")
                }}</span>
              </div>
            </div>
            <no-data v-show="!addressList.length" slot="empty"></no-data>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        v-if="createVisible"
        class="c-dialog"
        width="440px"
        :title="translateTitle('生成新充币地址')"
        :visible.sync="createVisible"
        :close-on-click-modal="false"
      >
        <div class="content">
          <el-input
            @input="onFilterBak"
            v-model.trim="bak"
            :placeholder="translateTitle('请输入新地址备注名')"
            maxlength="15"
          ></el-input>
          <div class="new_buttons">
            <span @click="createVisible = false" class="common-cancel">{{
              translateTitle("取消")
            }}</span>
            <el-button
              @click="onCreateConfirm"
              :loading="creatLoading"
              class="common-save"
              >{{ translateTitle("确认") }}</el-button
            >
          </div>
        </div>
      </el-dialog>
      <el-dialog
        class="c-dialog"
        width="440px"
        :title="translateTitle('修改备注名')"
        @close="onModifyClose"
        :visible.sync="modifyVisible"
        :close-on-click-modal="false"
      >
        <div class="content">
          <el-input
            @input="onFilterNewBak"
            v-model.trim="new_bak"
            maxlength="15"
            :placeholder="translateTitle('请输入新备注名')"
          ></el-input>
          <div class="new_buttons">
            <span @click="modifyVisible = false" class="common-cancel">{{
              translateTitle("取消")
            }}</span>
            <el-button
              @click="onModifyConfirm"
              :loading="modifyLoading"
              class="common-save"
              >{{ translateTitle("确认") }}</el-button
            >
          </div>
        </div>
      </el-dialog>
      <el-dialog
        class="c-dialog"
        width="440px"
        :title="translateTitle('确认删除')"
        :visible.sync="delVisible"
        :close-on-click-modal="false"
      >
        <div class="content">
          <div>{{ translateTitle("您确定要进行本次操作吗？") }}</div>
          <div class="new_buttons">
            <span @click="delVisible = false" class="common-cancel">{{
              translateTitle("取消")
            }}</span>
            <el-button
              @click="onDeleteConfirm"
              :loading="delLoading"
              class="common-save"
              >{{ translateTitle("确认") }}</el-button
            >
          </div>
        </div>
      </el-dialog>
    </template>
  </div>
</template>
<script>
import { showMessage } from "@/components/dialog/message.js";
import {
  // getAddress,
  apiGetFinanceAddressList,
  apiCreateFinanceAddress,
  apiSaveFinanceAddress,
  apiDelFinanceAddress,
} from "@/model/assets/index";
import QrcodeVue from "qrcode.vue";
import { apiGetStaticTableInfo } from "@/model/settings";
import NoData from "@/components/common/NoData";
import { onSelectBlur, onSelectFilter } from "@/assets/js/element";
import commonMixin from "@/components/common/commonMixin.vue";
export default {
  mixins: [commonMixin],
  components: {
    NoData,
    QrcodeVue,
  },
  props: ["data"],
  data() {
    return {
      // onSelectBlur,
      // onSelectFilter,
      ua_id: "",
      uaid: "",
      checked: false,
      dialogVisible: false,
      createVisible: false,
      modifyVisible: false,
      delVisible: false,
      modifyLoading: false,
      delLoading: false,
      creatLoading: false,
      dataLoading: true,
      qr_code: "",
      active: "",
      isRecharge: true,
      addressActive: "",
      coinInfo: [],
      chainMap: {},
      address: "",
      addressList: [],
      addressList4Tag: {},
      // addressOptions: [],
      addressData: {
        address: "",
        bak: "",
        qrcode_url: "",
        transactions: "",
        chain_tag: "",
        ua_id: "",
      },
      bak: "",
      new_bak: "",
      chain_tag: "",
      time: "",
      isModify: false,
      isScroll: false,
      qrcodeLoading: false,
    };
  },

  created() {
    this.default_chain = this.data.itemDetail.default_chain.toUpperCase();
    // this.chain_tag = this.data.itemDetail.default_chain.toUpperCase();
    this.isRecharge = this.data.itemDetail.in_status == 1 ? true : false;
    this.getStaticChainInfo();
  },

  computed: {
    coinInfoComputed() {
      let tempObj = [];
      for (const key in this.coinInfo) {
        if (this.data.itemDetail.coin_id == this.coinInfo[key].value) {
          tempObj.push({
            key: this.coinInfo[key].value,
            value: this.coinInfo[key].label,
            label: this.coinInfo[key].label.toUpperCase(),
          });
        }
      }
      if (tempObj.length > 0) {
        this.chain_tag = tempObj[0].label;
      } else {
        this.chain_tag = this.data.itemDetail.default_chain.toUpperCase();
      }
      let erc20 = {};
      let trc20 = {};
      tempObj.forEach(ele => {
        if (ele.label === "ERC20") erc20 = ele;
        if (ele.label === "TRC20") trc20 = ele;
      });
      let newtempObj = tempObj.filter(ele => {
        return ele.label != "ERC20" && ele.label != "TRC20";
      });
      if (JSON.stringify(erc20) != "{}") {
        newtempObj.push(erc20);
      }
      if (JSON.stringify(trc20) != "{}") {
        newtempObj.unshift(trc20);
      }
      // 默认erc20
      // tempObj.forEach((ele) => {
      //   if (ele.value == "erc20") {
      //     this.active = ele.value;
      //   }
      // });
      return newtempObj;
    },
    // getScroll() {
    //   if (this.addressList.length > 6) {
    //     return { "overflow-y": "auto", "overflow-x": "hidden" };
    //   }
    // },
    getScrollClass() {
      let tempVal = "";
      if (this.addressList.length > 6) {
        tempVal = "scroll-calss";
      } else {
        tempVal = "";
      }
      return tempVal;
    },
  },
  // watch: {
  //   addressData() {
  //     this.qrcodeLoading = true;
  //   }
  // },
  methods: {
    // onQrcodeLoad() {
    //   this.qrcodeLoading = false;
    // },
    // async getStaticCoinInfo() {
    //   await apiGetStaticTableInfo(57).then(({ data }) => {
    //     for (const key in data) {
    //       this.coinInfo.push({
    //         label: data[key].chain.toUpperCase(),
    //         value: data[key].coin_id,
    //       });
    //     }
    //   });
    // },
    //  async getStaticChainInfo() {
    //   await apiGetStaticTableInfo(56).then(({ data }) => {
    //    this.chainMap = data
    //   });
    // },
    // initCoinInfo(){
    //  this.coinInfo.forEach(ele=>{

    //    for(let item in this.chainMap){
    //      if(ele.label == this.chainMap[item].chain_tag.toUpperCase()){
    //        if(this.chainMap[item].status==1)
    //        ele.chain_status = this.chainMap[item].status
    //      }
    //    }
    //  })
    // },
    async getStaticChainInfo() {
      this.coinInfo = [];
      await apiGetStaticTableInfo(56).then(({ data }) => {
        this.chainMap = data;
      });
      // 获取29静态表 获取coin 费率 chain名称 item_id信息
      await apiGetStaticTableInfo(57).then(({ data }) => {
        for (const key in data) {
          for (let item in this.chainMap) {
            if (
              data[key].chain.toUpperCase() ==
                this.chainMap[item].chain_tag.toUpperCase() &&
              data[key].status == 1 &&
              this.chainMap[item].status == 1
            ) {
              this.coinInfo.push({
                label: data[key].chain.toUpperCase(),
                value: data[key].coin_id,
                rate_out: data[key].fee,
                is_default: data[key].is_default,
                chain_status: this.chainMap[item].status,
                status: data[key].status,
                minout: data[key].minout,
                maxout: data[key].maxout,
              });
            }
          }
        }
      });
    },

    selectChange(val) {
      this.addressList.forEach(item => {
        if (val == item.ua_id) {
          this.addressData = item;
          this.ua_id = val;
        }
      });
    },

    scrollAdress() {
      this.isScroll = true;
    },
    onCreate() {
      this.createVisible = true;
      this.time = parseInt(new Date().getTime() / 1000);
      this.bak = this.translateTitle(`新地址`) + this.time;
      this.isModify = false;
    },
    onModify() {
      this.modifyVisible = true;
      this.selectChange(this.ua_id);
      this.new_bak = this.addressData.bak;
    },
    onDelete() {
      this.delVisible = true;
      this.isModify = false;
    },
    //删除确认
    async onDeleteConfirm() {
      this.delLoading = true;
      const req = {
        ua_id: this.ua_id,
      };
      const { status, data } = await apiDelFinanceAddress(req);
      this.delLoading = false;
      if (status == 200) {
        this.getAddress(this.chain_tag);
        this.delVisible = false;
        showMessage({ message: "删除成功", type: "success" });
      }
    },
    //修改确认
    async onModifyConfirm() {
      if (this.new_bak) {
        if (this.new_bak == this.addressData.bak) {
          this.modifyVisible = false;
          return;
        } else {
          const req = {
            ua_id: this.ua_id,
            bak: this.new_bak,
          };
          this.modifyLoading = true;
          const { status, data } = await apiSaveFinanceAddress(req);
          this.modifyLoading = false;
          if (status == 200) {
            showMessage({
              message: this.translateTitle(`修改成功`),
              type: "success",
            });
            this.getAddress(this.chain_tag);
            this.new_bak = "";
            this.isModify = true;
            this.modifyVisible = false;
          }
        }
      } else {
        showMessage({
          message: this.translateTitle(`请输入新备注名`),
          type: "error",
        });
      }
    },
    onModifyClose() {
      this.new_bak = "";
    },
    onCreateClose() {
      this.bak = "";
    },
    mainClose() {
      this.isModify = false;
      this.dialogVisible = false;
      // this.chain_tag = "erc20";
    },

    //新增确认
    async onCreateConfirm() {
      if (this.bak) {
        this.creatLoading = true;
        const req = {
          item_id: this.data.item_id,
          bak: this.bak,
        };
        // if (!this.data.itemDetail.multi_chain_status) {
        //   this.active = "";
        // } else {
        //   req.chain_tag = this.chain_tag;
        // }
        req.chain_tag = this.chain_tag;
        const { status, data } = await apiCreateFinanceAddress(req);
        this.creatLoading = false;
        if (status == 200) {
          showMessage({
            message: this.translateTitle(`生成新充币地址成功`),
            type: "success",
          });
          this.getAddress(this.chain_tag);
          this.bak = "";
          this.createVisible = false;
        }
      } else {
        showMessage({
          message: this.translateTitle(`请输入新地址备注名`),
          type: "error",
        });
      }
    },
    // 选项卡切换
    onTypeChange(val) {
      this.isModify = false;
      this.chain_tag = val;
      // this.getAddress(val);
      this.onChangeTag(val);
    },
    //切换链选项卡
    onChangeTag(tagName) {
      this.active = tagName.toUpperCase();
      this.addressList = this.addressList4Tag[tagName.toLowerCase()];
      // this.addressList = data;  //pangpang注释
      // this.addressOptions = data; //pangpang注释

      if (!this.isModify) {
        if (this.addressList4Tag[tagName.toLowerCase()].length > 0) {
          this.addressData = this.addressList4Tag[tagName.toLowerCase()][0];
          this.ua_id = this.addressData.ua_id;
          this.addressActive = this.addressData.ua_id;
          // this.onAddressChange(this.ua_id);
        } else {
          this.addressList = [];
          this.addressData = [];
        }
      } else {
        this.onAddressChange(this.ua_id);
      }
      this.dialogVisible = true;
    },
    onAddressChange(val) {
      //   this.$refs.scorllRef.scrollTo(0,0)
      // //   window.scrollTo(0,0);   // chrome
      // // document.body.scrollTop = 0
      this.addressActive = val;
      this.addressList.forEach(item => {
        if (val == item.ua_id) {
          this.addressData = item;
          this.ua_id = val;
        }
      });
    },
    onFilterNewBak(val) {
      this.new_bak = val.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\$]/g, "");
    },
    onFilterBak(val) {
      this.bak = val.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\$]/g, "");
    },
    copySuccess() {
      showMessage({
        message: this.translateTitle(`内容复制成功`),
        type: "success",
      });
    },
    copyError() {
      showMessage({ message: this.translateTitle(`内容复制失败`) });
    },
    // 获取充币地址
    async getAddress(val) {
      // if (!this.checked) {
      //   showMessage({ message: "请先确认是否要充值" });
      //   return;
      // }  coinInfoComputed

      // this.chain_tag = val;  //pangpang注释
      const item_id = this.data.item_id;

      let params = {
        item_id: item_id,
      };
      // if (!this.data.itemDetail.multi_chain_status) {
      //   this.active = "";
      // } else {
      //   params.chain_tag_list = this.chain_tag;
      // }
      let chain_tag_temp = [];
      let chain_tag_strings = "";
      // if (!val) {
      this.coinInfoComputed.forEach(item => {
        chain_tag_temp.push(item.value);
      });
      chain_tag_strings = chain_tag_temp.join(",");

      params.chain_tag_list = chain_tag_strings;
      // } else {
      //   params.chain_tag_list = val;
      // }

      const { status, msg, data } = await apiGetFinanceAddressList(params);
      // apiGetFinanceAddressList(params).then(({ status, msg, data }) => {

      if (status === 200) {
        // if (val) {                          //pangpang 注释
        //   this.active = val.toUpperCase();
        // }
        this.active = this.chain_tag.toUpperCase();
        chain_tag_temp.forEach(tag => {
          this.addressList4Tag[tag.toLowerCase()] = [];
          data.forEach(ele => {
            if (tag.toLowerCase() == ele.chain_tag) {
              this.addressList4Tag[tag.toLowerCase()].push(ele);
            }
          });
        });

        this.addressList = this.addressList4Tag[this.chain_tag.toLowerCase()];
        // this.addressList = data;  //pangpang注释
        // this.addressOptions = data; //pangpang注释

        if (!this.isModify) {
          if (this.addressList4Tag[this.chain_tag.toLowerCase()].length > 0) {
            this.addressData = this.addressList4Tag[
              this.chain_tag.toLowerCase()
            ][0];
            this.ua_id = this.addressData.ua_id;
            this.addressActive = this.addressData.ua_id;
            // this.onAddressChange(this.ua_id);
          } else {
            this.addressList = [];
            this.addressData = [];
          }
        } else {
          this.onAddressChange(this.ua_id);
        }
        // if (!this.isModify) {
        //   if (data.length > 0) {
        //     this.addressData = data[0];
        //     this.ua_id = this.addressData.ua_id;
        //     this.addressActive = this.addressData.ua_id;
        //     // this.onAddressChange(this.ua_id);
        //   } else {
        //     this.addressList = [];
        //     this.addressData = [];
        //   }
        // } else {
        //   this.onAddressChange(this.ua_id);
        // }
        this.dialogVisible = true;
      } else {
        showMessage({ message: msg ? msg : status });
      }
    },
  },
};
</script>
<style lang="scss">
.en {
  .charge-fragment-content
    .c-dialog
    .el-dialog__body
    .address-content
    .left
    .crate-address {
    padding: 0 30px;
  }
  .charge-fragment-content
    .c-dialog
    .el-dialog__body
    .address-content
    .left
    .crate-address {
    left: 10px;
  }
}
</style>
<style lang="scss">
.atooltip {
  background: #ffffff !important;
  box-shadow: 0 2px 10px 0 rgba(53, 64, 82, 0.17);
  font-size: 14px;
  color: #354052 !important;
  // max-width:	400px;
}
.charge-fragment-content {
  /* width: 580px; */
  width: 440px;
  margin-right: 50px;
  min-height: 126px;
  background-color: #f5f5f7;
  padding: 15px 19px 20px 20px;
  border-radius: 3px;
  box-sizing: border-box;
  text-align: left;
  .is-checked .el-checkbox__inne {
    border-color: #e2e2e4 !important;
  }
  .common-cancel {
    // line-height: 20px !important;
    margin-right: 5px;
  }
  .common-save {
    margin-left: 5px;
    padding: 0px;
  }

  .maintenance-ing {
    margin-top: 5px;
    .maintenance {
      background-color: #c2c7d0 !important;
      color: #ffffff !important;
      border-radius: 3px;
      width: 200px;
      height: 40px;
      padding: 12px 20px;
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .no-data-box {
    border: 0;
    min-height: 320px;
  }

  .coin-name {
    font-size: 14px;
    font-weight: 500;
    color: #9aa1b1;
  }

  .explain-item {
    font-size: 14px;
    font-weight: 400;
    color: #9aa1b1;
  }
  .common-edit,
  .common-delete {
    cursor: pointer;
    width: 100px;
    height: 30px;
    line-height: 30px;
    margin: 0 20px 0 0;
    // padding: 8px 36px;
    border-radius: 3px;
    border: solid 1px #d60c30;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #d60c30;
  }

  .operate-item {
    // padding-top: 12px;
    .operate-checkbox {
      .el-checkbox__input {
        line-height: 9px;
      }
    }
    .look-address {
      margin-top: 15px;
      width: 100px;
      // padding: 7px 22px 10px;
      line-height: 32px;
      height: 32px;
    }
  }

  .show-address {
    color: #fff;
    width: 69px;
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 2px;
    background: #e2e2e4;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background-color: #e2e2e4;
    }
  }

  .el-checkbox {
    .el-checkbox__label {
      color: #9aa1b1 !important;
      font-size: 14px;
      font-weight: 400;
    }

    .el-checkbox__inner {
      border-color: #e2e2e4;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #3348f6;
    }
  }

  .c-dialog {
    .header {
      background: white;
      background-color: #fff;
      border-radius: 0 0 4px 4px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      border: 0;
      .nav {
        /* background: #f1f2f5; */
        background: #fff;
        display: flex;
        flex: 1;
        justify-content: space-between;
        font-size: 14px;
        .list-type-tab {
          .tab {
            width: 100px;
            height: 32px;
            line-height: 32px;
            margin-right: 10px;
            display: inline-block;
            padding: 0px;
            background: #fff;
            border-radius: 3px;
            border: solid 1px #c2c7d0;
            cursor: pointer;
            span {
              color: #354052;
              font-size: 14px;
              font-weight: 500;
            }
            &:hover {
              color: #354052;
              &:after {
                content: "";
                height: 2px;
                width: 80%;
                background-color: #354052;
              }
            }
            &.active {
              background-color: #d60c30;
              color: white;
              border: 0;
              span {
                color: #fff;
              }
            }
          }
        }
      }
    }
    &.create-addtess {
      .el-dialog {
        margin-top: 16vh !important;
      }
      .header-title {
        display: flex;
        align-items: center;
        .item-img {
          width: 30px;
          height: 30px;
        }
        .item-name {
          margin-left: 10px;
          font-weight: 500;
          color: #354052;
          font-size: 20px;
        }
        .item-tips {
          margin-left: 3px;
          font-weight: 400;
          color: #9aa1b1;
          font-size: 14px;
        }
      }
      .el-dialog__header {
        padding: 20px 30px;
        /* background: #f1f2f5; */
        background: #fff;
        .create-address-title {
          display: flex;
          justify-content: space-between;
          i {
            cursor: pointer;
          }
        }
        img {
          width: 30px;
          height: 30px;
          vertical-align: middle;
        }
        .title-data {
          height: 30px;
          font-size: 20px;
          font-weight: 500;
          color: #354052;
          vertical-align: middle;
          margin-left: 5px;
          width: fit-content;
          .sub-title-data {
            color: #9aa1b1;
            font-size: 14px;
            font-weight: normal;
          }
        }
      }
      .el-dialog__body {
        /* background: #f1f2f5; */
        background: #fff;
      }
    }
    .el-dialog__header {
      border-radius: 4px;
      padding: 20px 30px;

      .el-dialog__title {
        font-size: 20px;
      }
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog__body {
      border-radius: 4px;
      padding: 0px 30px 30px 30px;
      box-sizing: border-box;
      text-align: center;
      border: 5px;
      overflow: hidden;
      .address-content {
        background: #ffffff;
        border: 1px solid #e2e2e4;
        display: flex;
        border-radius: 4px;
        // height: 448px;

        .left {
          position: relative;
          box-sizing: border-box;
          // border-top-left-radius: 4px;
          // border-right: 1px solid #d60c30;
          width: 370px;
          .scrollbar-default {
            ::-webkit-scrollbar {
              visibility: hidden;
              width: 4px;
              height: 4px;
              position: absolute;
              right: -100px;
            }

            ::-webkit-scrollbar-thumb {
              visibility: hidden;
              border-radius: 4px;
              position: absolute;
              right: -100px;
            }

            &:hover {
              ::-webkit-scrollbar {
                visibility: visible;
                position: absolute;
                right: -100px;
              }

              ::-webkit-scrollbar-thumb {
                background: #7f7f7f;
                border-radius: 4px;
                visibility: visible;
                position: absolute;
                right: -100px;
              }
            }
          }
          .scroll-bar {
            height: 379px;
          }
          .scroll-calss {
            overflow-y: auto;
            width: 380px;
            &:hover {
              overflow-y: auto;
              overflow-x: hidden;
              .active {
                border-right: 1px solid #ffffff;
              }
            }
          }

          .coin-info-content {
            width: 380px;
            min-height: 379px;
            box-sizing: border-box;
            position: relative;
            padding: 0 10px 0 0;
            user-select: text;
            .coin-info-line {
              position: absolute;
              width: 1px;
              right: 10px;
              top: 0;
              background: #e81b40;
              height: 100%;
            }
            .coin-info-gap {
              height: 10px;
            }
            li {
              list-style: none;
              overflow: visible;
              height: 50px;
              box-sizing: border-box;
              background-color: #fff;
              cursor: pointer;
              font-size: 14px;
              color: #354052;
              border-bottom: 1px solid #e2e2e4;
              // border-top: 0px;
              // border: 0;
              border-left: 3px solid #f8f9fc;
              box-sizing: border-box;
              &:first-child {
                border-top: 0;
              }
              &.active {
                border: solid 1px #e2e2e4;
                border-top: 0;
                color: #e81b40;
                border-right-color: #fff;
                background: #ffffff;
                width: 371px;
                position: relative;
                border: 1px solid #e81b40;
                border-right: 0;
              }
              .top {
                padding: 6px 30px 0 12px;
                display: flex;
                justify-content: space-between;
                // height: 18px;
                .img-content {
                  display: flex;
                  align-items: center;
                  img {
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                  }
                }
                span {
                  font-size: 14px;
                  font-weight: 500;
                }
              }
              .bottom {
                text-align: left;
                padding-left: 12px;
                font-size: 12px;
                font-weight: 400;
                div {
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: 350px;
                  white-space: nowrap;
                }
              }
            }
          }
          .crate-address {
            // position: absolute;
            // bottom: 0px;
            width: 100%;
            height: 70px;
            border-radius: 2px;
            font-size: 14px;
            box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.04);
            // background-color: #ffffff;
            align-items: center;
            display: flex;
            justify-content: center;
            border-right: 1px solid #e81b40;
            box-sizing: border-box;
            .create-btn {
              // position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              i {
                font-size: 18px;
                margin-right: 5px;
                color: #ffffff;
              }
              width: 200px;
              cursor: pointer;
              color: #ffffff;
              // font-size: 14px;
              background-image: linear-gradient(
                264deg,
                #ff486d 6%,
                #c61b3d 40%
              );
              height: 40px;
              line-height: 40px;
              padding: 0px 10px 0px 10px;
              border-radius: 3px;
              // border: solid 1px #e2e2e4;
              background-color: #e2e2e4;
              box-sizing: border-box;
            }
            i {
              font-size: 18px;
              color: #ffffff;
            }
            .add-icon {
              position: absolute;
              left: 116px;
              top: 26px;
              width: 18px;
              height: 18px;
              border: solid 1px #ffffff;
              border-radius: 50%;
              color: #ffffff;
              line-height: 1;
            }
          }
        }
        .right {
          width: 388px;
          border: 1px solid #e81b40;
          border-left: 0;
          box-sizing: border-box;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          .top-title {
            text-align: left;
            // margin: 82px 0px 8px 0px;
            margin: 4px 9px 17px 31px;
            height: 30px;

            display: flex;
            // justify-content: center;
            align-items: center;
            user-select: text;
            img {
              width: 24px;
              height: 24px;
              margin: 5px 10px 0 0;
            }
            p {
              font-size: 14px;
              font-weight: 500;
              color: #354052;
              height: 14px;
            }
          }
        }
        .no-data-right {
          border: none;
        }
        .no-data-left {
          .crate-address {
            border: none;
            // border-right: 1px solid #e2e2e4;
          }
        }
      }
      /* 选择地址框 */
      .address-select {
        width: 380px;
        margin-top: 20px;
        // min-height: 50px;
        .el-select-dropdown {
          .el-scrollbar {
            // min-height: 40px;
            .el-select-dropdown__item {
              min-height: 53px;
              display: flex;
              // align-items: center;
              flex-direction: column;
              justify-content: center;
              p {
                font-size: 14px;
                line-height: 16px;
                white-space: nowrap;
                word-break: break-all;
                &:first-child {
                  margin-bottom: 6px;
                }
              }
              &.add-address {
                width: 100%;
                height: 40px;
                min-height: 40px;
                color: #14c781;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                position: absolute;
                left: 0;
                bottom: 0;
                .add-icon {
                  width: 16px;
                  height: 16px;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }

      p {
        font-size: 14px;
        height: 14px;
      }
      .iconduigou {
        float: right;
        color: #ffffff;
      }
      .selected {
        .iconduigou {
          color: #14c781;
        }
      }

      .currentImg {
        margin-top: 40px;
        width: 43px;
        height: 43px;
        border-radius: 50%;
        margin-bottom: 14px;
      }
      .name {
        color: #354052;
        font-size: 16px;
        font-weight: 500;
      }
      .qr-code {
        width: 100px;
        height: 100px;
        // border: 5px solid #b4bac6;
        box-sizing: border-box;
        margin: 81px auto 24px;
        user-select: text;
      }
      .address {
        color: #354052;
        width: 280px;
        margin: 0px 50px;
        height: 36px;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        span {
          text-align: left;
          user-select: text;
        }
        .iconicon-copy {
          font-size: 16px;
          margin-left: 5px;
        }
      }
      i {
        cursor: pointer;
        color: rgba(39, 198, 131, 0.8);
        &:hover {
          color: rgba(39, 198, 131, 1);
        }
      }
      .collection {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 20px;

        .collection-title {
          color: #354052;
          font-size: 14px;
          font-weight: 500;
        }
        .collection-num {
          color: #354052;
          margin-left: 10px;
          font-size: 14px;
          font-weight: 500;
        }
      }
      .buttons {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        .modify {
          width: 140px;
          height: 40px;
          border-radius: 2px;
          border: solid 1px #e2e2e4;
          background-color: #ffffff;
          line-height: 40px;
          box-sizing: border-box;
          color: #e2e2e4;
          cursor: pointer;
        }
        .delete {
          width: 140px;
          height: 40px;
          border-radius: 2px;
          border: solid 1px #ff7481;
          background-color: #ffffff;
          line-height: 40px;
          box-sizing: border-box;
          margin-left: 10px;
          color: #ff7481;
          cursor: pointer;
        }
      }
      .new_buttons {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        .cancel {
          width: 140px;
          height: 40px;
          border-radius: 2px;
          border: solid 1px #e2e2e4;
          background-color: #ffffff;
          line-height: 40px;
          box-sizing: border-box;
          color: #e2e2e4;
          cursor: pointer;
        }
        .confirm {
          width: 140px;
          height: 40px;
          border-radius: 2px;
          border: solid 1px #e2e2e4;
          background-color: #e2e2e4;
          // line-height: 40px;
          box-sizing: border-box;
          margin-left: 10px;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
